import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Header from "../../Components/Header.jsx";
import axios from "axios";
import { Button } from "react-bootstrap";
import DescargarBuro from "./DescargarBuro.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import { autorizacionInformacion } from "./AutorizacionInformacion.jsx";
import { correoAtencionCliente } from "./CorreoAtencionCliente.jsx";
import { preAprobacion } from "./PreAprobacion.jsx";
import { enviarNetbank } from "./EnviarNetbank.jsx";

function StepTwentyone () {
  const Step = "/step23"; //this is the next step
  const manejarClick = async () => {
    try {
      await autorizacionInformacion();

      await preAprobacion();

      await enviarNetbank();

      await correoAtencionCliente();

      handleUpdate(Step);

      navigate(Step);
    } catch (error) {
      console.error("Error al pre-aprobar solicitud:", error);
      setError({
        message: "Ocurrió un error al pre-aprobar la solicitud",
        type: "danger",
      });
    }
  };
  const ID = localStorage.getItem("idSolicitud");
  const token = useLoaderData();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const nombres = localStorage.getItem("nombres");
  const primerApellido = localStorage.getItem("primerApellido");
  const segundoApellido = localStorage.getItem("segundoApellido");
  const numeroDocumento = localStorage.getItem("numeroDocumento");
  const idSolicitud = localStorage.getItem("idSolicitud");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleFileChange = async (event) => {
    setUploadSuccess(false);
    let file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("archivo", file);
      formData.append("idSolicitud", localStorage.getItem("idSolicitud"));
      formData.append("tipo", "AUTORIZACION FIRMADA");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BACKEND}/creditos/archivos/autorizacion-firmada`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Archivo subido con éxito:", response.data);
        setUploadSuccess(true);
      } catch (error) {
        console.error("Error uploading image:", error.response.data.mensaje);
        setUploadSuccess(false);
        setError({
          message: error.response.data.mensaje,
          type: "danger",
        });
      }
    }
  };
  function blobToBase64 (blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const handleDownload = async (event) => {
    try {
      const response = await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${idSolicitud}/autorizacion-informacion`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      console.log(response.data.datos);

      DescargarBuro(response.data.datos);
      setIsButtonDisabled(false);
    } catch (error) {
      console.error("Error uploading image:", error.response.data.mensaje);

      setError({
        message:
          error.response.data.mensaje === "jwt expired"
            ? "Su session ha expirado"
            : "Ocurrió un error",
        type: "danger",
      });
    }
  };

  return (
    <div>
      <Header textHeader="Verificación del buró" />
      <ErrorHandling error={error} clearError={clearError} />
      <div className="container">
        <h2 className="text-center">Autorización de información</h2>
        <p>
          El siguiente texto es importante para que podamos revisar tu historial
          crediticio, por lo que debes descargarlo y subirlo firmado a pulso en
          la opción “Adjuntar documento firmado”
        </p>
        <div className="bg-white custom-effect rounded p-5">
          <p>A quien corresponda:</p>
          <p>
            {" "}
            Yo {nombres} {primerApellido} {segundoApellido} con C. I. Nro.{" "}
            {numeroDocumento} autorizo en forma expresa a GENCORP S.R.L. a
            solicitar información sobre mis antecedentes crediticios y otras
            cuentas por pagar de carácter económico, financiero y comercial
            registrados en el BI y en la CIC de la Autoridad del Sistema
            Financiero (ASFI) mientras dure mi relación contractual con el
            citado usuario.
          </p>
          <p>
            Asimismo autorizo a incorporar los datos crediticios y de cuentas
            por pagar a carácter económico, financiero y comercial derivados de
            la relación con GENCORP S.R.L. en la(s) base(s) de datos de
            propiedad de los Burós de Información que cuenten con licencia de
            funcionamiento de ASFI y la CIC.
          </p>
          <div className="row">
            <div className="col-9">
              <Button
                variant={!uploadSuccess ? "primary" : "success"}
                disabled={isButtonDisabled}
              >
                <label htmlFor="file">
                  <i className="bi bi-paperclip"></i>
                  {uploadSuccess
                    ? "Archivo Cargado Correctamente"
                    : "Adjuntar el documento Firmado"}
                  <span className="text-danger">
                    <strong>*</strong>
                  </span>{" "}
                </label>
                <input
                  id="file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="application/pdf,image/*"
                />
              </Button>
            </div>
            <div className="col-3">
              <Button onClick={handleDownload} className="primary">
                Descargar
              </Button>
            </div>
          </div>
        </div>
        <div className="alto">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <button
                type="button"
                onClick={manejarClick}
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                disabled={!uploadSuccess} // Deshabilita el botón si no se ha cargado un PDF
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwentyone;
