import { Popover, OverlayTrigger, ButtonToolbar } from "react-bootstrap";
function IconWithPopover({ textPopOver }) {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p className="m-4">{textPopOver} </p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popoverTop}
      >
        <i className="bi bi-question-circle-fill text-success"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}
export default IconWithPopover;
