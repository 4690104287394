import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import "./Maps.css";

function MapApp({ setLocation }) {
  const [position, setPosition] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const mapStyles = {
    height: "400px",
    width: "400px",
  };

  const defaultCenter = {
    lat: -16.5,
    lng: -68.1193,
  };

  const handleMapClick = async (event) => {
    const newPosition = event.latLng.toJSON();
    setPosition(newPosition);
    const geolocationDetails = await reverseGeocode(newPosition);
    setLocation(geolocationDetails);
    setShowMap(false);
  };

  const reverseGeocode = async (latLng) => {
    try {
      const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${googleMapsApiKey}`
      );

      if (response.data.results.length > 0) {
        const result = response.data.results[0];
        const department =
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";
        const city =
          result.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "";
        const neighborhood =
          result.address_components.find((component) =>
            component.types.includes("sublocality")
          )?.long_name || "";
        const district =
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        return {
          department,
          city,
          neighborhood,
          district,
        };
      }
    } catch (error) {
      console.error(error);
    }

    return {};
  };

  return (
    <div>
      <span onClick={() => setShowMap(!showMap)}>
        <center>
          {" "}
          <img
            src={require("./casual-life-3d-pink-location-marker.png")}
            alt=""
            className="w-50 w-sm-25"
          />
        </center>
      </span>

      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        {showMap && (
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={12}
            center={position || defaultCenter}
            onClick={handleMapClick}
          >
            {position && <Marker position={position} />}
          </GoogleMap>
        )}
      </LoadScript>
    </div>
  );
}

export default MapApp;
