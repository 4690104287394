import React from "react";
import { getDaysInMonth, format } from "date-fns";

const SelectorDeFecha = ({ value, onChange, minYearSet }) => {
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - minYearSet;
  const years = Array.from(
    new Array(100),
    (val, index) => currentYear - index
  ).filter((year) => year <= minYear);

  const months = Array.from(new Array(12), (val, index) => index);

  const days = value
    ? Array.from(
        new Array(
          getDaysInMonth(new Date(value.getFullYear(), value.getMonth()))
        ),
        (val, index) => index + 1
      )
    : [];

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleYearChange = (event) => {
    const newYear = event.target.value
      ? parseInt(event.target.value, 10)
      : null;
    if (newYear) {
      onChange(
        new Date(
          newYear,
          value ? value.getMonth() : 0,
          value ? value.getDate() : 1
        )
      );
    } else {
      onChange(null);
    }
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value
      ? parseInt(event.target.value, 10)
      : null;
    if (value && newMonth !== null) {
      onChange(new Date(value.getFullYear(), newMonth, value.getDate()));
    }
  };

  const handleDayChange = (event) => {
    const newDay = event.target.value ? parseInt(event.target.value, 10) : null;
    if (value && newDay !== null) {
      onChange(new Date(value.getFullYear(), value.getMonth(), newDay));
    }
  };

  return (
    <div className="d-flex gap-3">
      <select
        value={value ? value.getFullYear() : ""}
        onChange={handleYearChange}
        className="form-control"
      >
        <option value="">Año</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <select
        value={value ? value.getMonth() : ""}
        onChange={handleMonthChange}
        className="form-control"
        disabled={!value}
      >
        <option value="">Mes</option>
        {months.map((month) => (
          <option key={month} value={month}>
            {monthNames[month]}
          </option>
        ))}
      </select>
      <select
        value={value ? value.getDate() : ""}
        onChange={handleDayChange}
        disabled={!value}
        className="form-control"
      >
        <option value="">Día</option>
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectorDeFecha;
