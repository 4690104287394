import React, { useState } from "react";
import Header from "../../Components/Header";
import { FormSelect, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function StepThirtytwo() {
  const simulador = JSON.parse(localStorage.getItem("simulador"));
  console.log("datos:", simulador);
  const [bank, setBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const isNextButtonEnabled = bank !== "" && accountNumber !== "";
  return (
    <div>
      <Header textHeader="Datos del préstamo" />
      <div className="container">
        <p className="text-center mt-4">
          Por favor confirma la información de tu préstamo
        </p>
        <div className="row">
          <div className="col-md-4 mt-4">
            Monto Solicitado: {simulador.monto}
          </div>
          <div className="col-md-4 mt-4">Plazo: {simulador.plazo}</div>
          <div className="col-md-4 mt-4">
            Fecha Tentativa de Pago: {simulador.dia}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-4">
            <div className="form-group">
              <label htmlFor="banco">
                Banco al cual realizaremos tu desembolso{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <FormSelect
                name="banco"
                id="banco"
                className="form-control rounded-pill"
              >
                <option value="">Seleccionar</option>
                <option value="pilot">pilot</option>
              </FormSelect>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="form-group">
              <label htmlFor="cuenta">
                Número de cuenta
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="number"
                name="cuenta"
                id="cuenta"
                className="form-control rounded-pill"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            <Link to={isNextButtonEnabled ? "/step33" : "#"}>
              <Button
                type="button"
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                disabled={!isNextButtonEnabled}
              >
                Siguiente
              </Button>
            </Link>
          </div>
          <div className="col-md-3 p-md-2 p-2 px-4">
            <Link to="/step31">
              <button
                type="submit"
                className="w-100 btn btn-light btn-lg rounded-pill shadow"
              >
                Atrás
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThirtytwo;
