import React, { useState } from "react";
import axios from "axios";
import Header from "../../Components/Header.jsx";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useLoaderData } from "react-router-dom";

function StepEighteen () {
  const token = useLoaderData();
  const navigate = useNavigate();
  console.log("token18:", token);

  const [opcion1, setOpcion1] = useState("Escoge una opción");
  const [opcion2, setOpcion2] = useState("Escoge una opción");
  const [valorInmuebles, setValorInmuebles] = useState("");
  const [valorVehiculos, setValorVehiculos] = useState("");
  const [message, setMessage] = useState(false);

  const handleOpcion1Change = (e) => {
    setOpcion1(e.target.value);
  };

  const handleOpcion2Change = (e) => {
    setOpcion2(e.target.value);
  };

  const handleValorInmueblesChange = (e) => {
    // Validar que el valor ingresado sea un número (opcional)
    const value = e.target.value;
    if (!isNaN(value)) {
      setValorInmuebles(value);
    }
  };

  const handleValorVehiculosChange = (e) => {
    // Validar que el valor ingresado sea un número (opcional)
    const value = e.target.value;
    if (!isNaN(value)) {
      setValorVehiculos(value);
    }
  };

  // Función para verificar si todos los campos requeridos están completos
  const areAllFieldsCompleted = () => {
    if (
      (opcion1 === "Si" && !valorInmuebles) ||
      (opcion2 === "Si" && !valorVehiculos)
    ) {
      return false;
    }
    return true;
  };

  const submitFinancialInfo = async () => {
    const idSolicitud = localStorage.getItem('idSolicitud')

    const url = `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${idSolicitud}/informacion-financiera`

    const headers = {
      Authorization: `Bearer ${token}`, // Use token from loaderData
      "Content-Type": "application/json",
    };

    const data = {
      valorInmuebles: valorInmuebles || 0,
      valorVehiculos: valorVehiculos || 0,
    };

    try {
      const response = await axios.put(url, data, { headers });

      console.log(response.data);
      navigate("/step19");
    } catch (error) {
      console.error("There was an error!", error);
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
      }, 2000);
    }
  };

  return (
    <div>
      <Header textHeader="Información financiera" />

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {" "}
            {message && (
              <div className="alert alert-danger">
                Hubo un error, inténtelo de nuevo
              </div>
            )}
            <Form.Group>
              <Form.Label>
                ¿Tienes inmuebles o terrenos propios?{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </Form.Label>
              <Form.Select
                className="form-control rounded-pill"
                value={opcion1}
                onChange={handleOpcion1Change}
              >
                <option>No</option>
                <option>Si</option>
              </Form.Select>
            </Form.Group>
            {opcion1 === "Si" && (
              <Form.Group>
                <Form.Label>
                  Valor aproximado de tus inmuebles o terrenos{" "}
                  <span className="text-danger">
                    <strong>*</strong>
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form-control rounded-pill"
                  value={valorInmuebles}
                  onChange={handleValorInmueblesChange}
                  placeholder="Monto expresado en Bs"
                />
              </Form.Group>
            )}
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>
                ¿Tienes vehículos propios?{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </Form.Label>
              <Form.Select
                className="form-control rounded-pill"
                value={opcion2}
                onChange={handleOpcion2Change}
              >
                <option>No</option>
                <option>Si</option>
              </Form.Select>
            </Form.Group>
            {opcion2 === "Si" && (
              <Form.Group>
                <Form.Label>
                  Valor aproximado de tus vehículos{" "}
                  <span className="text-danger">
                    <strong>*</strong>
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form-control rounded-pill"
                  value={valorVehiculos}
                  onChange={handleValorVehiculosChange}
                  placeholder="Monto expresado en Bs"
                />
              </Form.Group>
            )}
          </div>
        </div>
        <div className="alto">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              {areAllFieldsCompleted() ? (
                <button
                  type="submit"
                  onClick={submitFinancialInfo}
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                >
                  Siguiente
                </button>
              ) : (
                <button
                  type="button"
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  disabled={true}
                >
                  Siguiente
                </button>
              )}
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="/step17">
                <button className="w-100 btn btn-light btn-lg rounded-pill shadow">
                  Atrás
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepEighteen;
