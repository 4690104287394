import React from "react";
import Header from "../../Components/Header.jsx";
import { useNavigate } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import "./Step8.css";

function StepEight() {
  const navigate = useNavigate();
  const Step = "/step9"; //this is the next step
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  return (
    <div>
      <Header
        textHeader={
          <React.Fragment>
            Carnet de Identidad
            <br />
            Anverso
          </React.Fragment>
        }
      />
      <div className="container">
        <div className="">
          <center>
            <img
              src={require("./web-11.jpg")}
              alt="Persona con Celular"
              className="img-fluid w-50 m-4 rounded-4 custom-effect"
            />
          </center>
        </div>
        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <center>
              <div className="col-md-6 p-md-2 p-2 px-4">
                <button
                  onClick={manejarClick}
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                >
                  Activar Cámara
                </button>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepEight;
