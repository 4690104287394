import React from "react";
import { Form } from "react-router-dom";
import Header from "../../Components/Header.jsx";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

function StepTwentythree() {
  const name = localStorage.getItem("nombres");
  const primerApellido = localStorage.getItem("primerApellido");
  const Step = "/step23"; //this is the next step
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
  };
  return (
    <div>
      <Header textHeader="¡GRACIAS POR CONFIAR EN KIVO!" />
      <div className="container mt-md-5">
        <div className="bg-white custom-effect rounded  p-3 w-75 mx-auto">
          <p>
            ¡Hola {name} {primerApellido}!
          </p>
          <p>
            ¡Gracias por enviar tu solicitud! La revisaremos a detalle para
            brindarte una respuesta lo más antes posible. Te recomendamos estar
            pendiente de tu celular, ya que ahí te notificaremos sobre la
            preaprobación de tu solicitud.
          </p>
          <p>¡Saludos financieros!</p>
          <p> El equipo de Kivo Cash</p>
        </div>
        <div className="alto">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Form action="/logout" method="post">
                <button
                  type="submit"
                  className="w-100 btn btn-danger btn-lg rounded-pill shadow"
                >
                  Salir
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwentythree;
