import React from "react";
import { Modal, Button } from "react-bootstrap";

function DisclaimerModal(props) {
  const modalBodyStyle = {
    maxHeight: "calc(150vh - 210px)", // Adjust height as needed
    overflowY: "auto",
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <iframe
          src={props.url}
          width="100%"
          height="400" // Adjust as needed
          title="Disclaimer Content"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary text-white"
          onClick={props.handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DisclaimerModal;
