import React, { useState } from "react";
import Header from "../../Components/Header.jsx";
import IconWithPopover1 from "./IconWithPopover.jsx";
import { Form } from "react-bootstrap";
import { Link } from 'react-router-dom';

function StepTwentynine() {
  const [formData, setFormData] = useState({
    genero: "",
    estadoCivil: "",
    referenciaPersonal: "",
    celularReferencia: "",
    vinculoReferencia: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "referenciaPersonal" && !/^[A-Za-z\s]*$/.test(value)) return;
    if (name === "celularReferencia" && !/^\d{0,8}$/.test(value)) return;
    setFormData({ ...formData, [name]: value });
  };

  const isContinueDisabled = () => {
    return !Object.values(formData).every((value) => value.trim() !== "");
  };

  return (
    <div>
      <Header textHeader="Datos Personales" />
      <p className="text-center">Completa tus datos</p>
      <div className="container">
        <div className="row">
          
          <div className="col-md-4 mt-4">
            <div className="form-group">
              <label>
                Género
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <Form.Select
                name="genero"
                className="form-control rounded-pill"
                onChange={handleInputChange}
              >
                <option value="">Seleccionar</option>
                <option value="Femenino">Femenino</option>
                <option value="Masculino">Masculino</option>
                <option value="Otro">Otro</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="form-group">
              <label>
                Estado Civil
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <Form.Select
                name="estadoCivil"
                className="form-control rounded-pill"
                onChange={handleInputChange}
              >
                <option value="">Seleccionar</option>
                <option value="Soltero(a)">Soltero(a)</option>
                <option value="Casado(a)">Casado(a)</option>
                <option value="Viudo(a)">Viudo(a)</option>
                <option value="Divorciado(a)">Divorciado(a)</option>
                <option value="Unión libre">Unión libre</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-4">
            <div className="form-group rounded-pill">
              <label>
                Referencia Personal
                <span className="text-danger">
                  <strong>*</strong>
                </span>{" "}
                <IconWithPopover1 />
              </label>
              <input
                type="text"
                name="referenciaPersonal"
                className="form-control rounded-pill"
                onChange={handleInputChange}
                value={formData.referenciaPersonal}
              />
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="form-group">
              <label>
                Celular de su Referencia Personal
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="celularReferencia"
                className="form-control rounded-pill"
                onChange={handleInputChange}
                value={formData.celularReferencia}
              />
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="form-group">
              <label>
                Vínculo con su Referencia Personal
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <Form.Select
                name="vinculoReferencia"
                className="form-control rounded-pill"
                onChange={handleInputChange}
              >
                <option value="">Seleccionar</option>
                <option value="Hermano(a)">Hermano(a)</option>
                <option value="Padre/madre">Padre/madre</option>
                <option value="Amigo(a)">Amigo(a)</option>
                <option value="Esposo(a)">Esposo(a)</option>
                <option value="Primo(a)">Primo(a)</option>
                <option value="Tío(a)">Tío(a)</option>
                <option value="Abuelo(a)">Abuelo(a)</option>
                <option value="Otros">Otros</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
            <Link to={isContinueDisabled() ? "#" : "/Step30"}>
                <button
                  type="button"
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  disabled={isContinueDisabled()}
                >
                  Siguiente
                </button>
              </Link>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <button
                type="submit"
                className="w-100 btn btn-light btn-lg rounded-pill shadow"
              >
                Atrás
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwentynine;
