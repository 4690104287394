import React, { useState, useEffect } from "react";
import { Form } from "react-router-dom"; // Asegúrate de tener instalado 'react-bootstrap'

const PreventBackButton = () => {
  const [showError, setShowErrorRaw] = useState(false);

  const setShowError = (value) => {
    setShowErrorRaw(value);
    if (!value) {
      // Si se está cerrando el mensaje, agrega nuevamente una entrada en el historial
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      if (window.history.state !== null) {
        setShowError(true);
      }
    };

    // Agrega una entrada falsa en el historial
    window.history.pushState(null, null, window.location.pathname);
    window.history.pushState(null, null, window.location.pathname);

    // Escucha el evento de cambio de estado del historial
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div>
      {showError && (
        <div className="alert bg-white error-modal rounded-4 border border-black border-2 p-md-4 text-center mt-5">
          <h5 className="mt-4">En esta pantalla, no puedes retroceder.</h5>
          <div className="row ">
            <div className="w-100 p-2">
              <button
                className="w-100 btn btn-primary text-white btn-lg rounded-pill shadow fs-6 mt-4"
                onClick={() => setShowError(false)}
              >
                Continuar con la solicitud
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreventBackButton;
