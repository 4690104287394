import React from "react";

function Header({textHeader}) {
  return (
    <div>
      <h1 className="text-center mt-n4"><strong>{textHeader}</strong></h1>
    </div>
  );
}

export default Header;