import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import Header from "../../Components/Header.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import Calendario from "../../Components/Calendario.jsx";
import { format } from "date-fns";
import {
  IconWithPopover1,
  IconWithPopover2,
  IconWithPopover3,
  IconWithPopover4,
} from "./IconWithPopover.jsx";
import "./Step15.css";
import { Form } from "react-bootstrap";
import { obtenerListaProfesiones } from "./ListaProfesiones.jsx";
import { correoAtencionCliente } from "./CorreoAtencionCliente.jsx";
import { preAprobacion } from "./PreAprobacion.jsx";
import { enviarNetbank } from "./EnviarNetbank.jsx";

function StepFifteen() {
  const Step = "/step23"; //this is the next step
  const manejarClick = async () => {
    try {
      await preAprobacion();

      await enviarNetbank();

      await correoAtencionCliente();

      handleUpdate(Step); // Pasa Step como argumento
      navigate(Step);
    }catch (error) {
        console.error("Error al pre-aprobar solicitud:", error);
        setError({
          message: "Ocurrió un error al pre-aprobar la solicitud",
          type: "danger",
        });
      }
  };
  const manejarRechazo = () => {
    handleUpdate("/rechazo"); // Pasa Step como argumento
    navigate("/rechazo");
  };
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const token = useLoaderData();
  console.log("token15:", token);
  const [selectedDate, setSelectedDate] = useState(null);
  const [fechaIngresoTrabajo, setFechaIngresoTrabajo] = useState(null);
  const [ingresoMensual, setIngresoMensual] = useState("");
  const [dependientes, setDependientes] = useState("");
  const [otrosIngresos, setOtrosIngresos] = useState("");
  const [montoOtrosIngresos, setMontoOtrosIngresos] = useState("");
  const [origenOtrosIngresos, setOrigenOtrosIngresos] = useState("");
  const [especifiqueOtrosIngresos, setEspecifiqueOtrosIngresos] = useState("");
  const [gastosMensuales, setGastosMensuales] = useState("");
  const [profesion, setProfesion] = useState("");
  const [rechazo, setRechazo] = useState(false);
  const [mostrarCamposOtrosIngresos, setMostrarCamposOtrosIngresos] =
    useState(false);
  const handleOtrosIngresosChange = (e) => {
    setOtrosIngresos(e.target.value);
    if (e.target.value === "si") {
      setMostrarCamposOtrosIngresos(true);
    } else {
      setMostrarCamposOtrosIngresos(false);
      setMontoOtrosIngresos("");
      setOrigenOtrosIngresos("");
      setEspecifiqueOtrosIngresos("");
    }
  };
  const [listaProfesiones, setListaProfesiones] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchProfesiones = async () => {
      try {
        const profesiones = await obtenerListaProfesiones(); // Llama a la función desde el componente externo
        setListaProfesiones(profesiones);
        console.log("error profe", profesiones);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("segundo error", error.message);
        setError({
          message: error.message,
          type: "danger",
        });
      }
    };

    fetchProfesiones();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      setFechaIngresoTrabajo(formattedDate);
    }
  }, [selectedDate]);

  const handleProfesion = (e) => {
    if (e.target.value === "Select") {
      setProfesion(false);
      setRechazo(false);
    } else if (
      e.target.value === "ABOGADO(A)" ||
      e.target.value === "MILITAR" ||
      e.target.value === "POLICIA"
    ) {
      setRechazo(true);
      setProfesion(e.target.value);
    } else {
      setProfesion(e.target.value);
      setRechazo(false);
    }
  };
  console.log("rechazado?", rechazo);

  const navigate = useNavigate();
  // Verificar si todos los campos requeridos están completos
  const handleSubmit = async (e) => {
    e.preventDefault();
    const valorInmuebles = 0;
    const valorVehiculos = 0;

    if (areAllFieldsCompleted()) {
      const requestData = {
        ingresos: ingresoMensual,
        dependientes: parseInt(dependientes, 10),
        otrosIngresos: mostrarCamposOtrosIngresos ? montoOtrosIngresos : "0",
        origenOtrosIngresos,
        especifiqueOtrosIngresos,
        egresos: gastosMensuales,
        fechaIngresoTrabajo: fechaIngresoTrabajo,
        profesion,
        valorInmuebles,
        valorVehiculos,
        pasoActual: 16,
      };

      console.log("Sending data:", requestData);

      const idSolicitud = localStorage.getItem("idSolicitud");

      let config = {
        method: "PUT",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${idSolicitud}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(requestData),
      };
      try {
        const response = await axios.request(config);
        console.log("Response data:", response.data.datos.pasoActual);
        manejarClick();
      } catch (error) {
        console.error("Error uploading content", error.response.data.mensaje);
        setError({
          message: error.response.data.mensaje,
          type: "danger",
        });
      }
    } else {
      console.error("All fields are not completed!");
    }
  };

  const areAllFieldsCompleted = () => {
    return (
      !!ingresoMensual &&
      !!dependientes &&
      !!gastosMensuales &&
      !!selectedDate &&
      !!profesion
    );
  };
  useEffect(() => {
    console.log("¿Todos los campos están completos?", areAllFieldsCompleted());
    console.log("¿Otros ingresos es 'si'", otrosIngresos === "si");
    console.log("monto otros ingresos está lleno:", !!montoOtrosIngresos);
    console.log("origen otros ingresos está lleno:", !!origenOtrosIngresos);
    console.log("especifique otros ingresos", !!especifiqueOtrosIngresos);
    console.log("profesion", !!profesion);
  }, [
    otrosIngresos,
    montoOtrosIngresos,
    origenOtrosIngresos,
    especifiqueOtrosIngresos,
    areAllFieldsCompleted,
  ]);

  return (
    <div>
      <div>
        <Header textHeader={"Información Financiera"} />
        <ErrorHandling error={error} clearError={clearError} />
        <h5 className="text-center">
          Expresa toda tu información en Bolivianos
        </h5>
      </div>
      {!loading ? (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="ingresoMensual" className="mb-3 mt-4">
                      Ingreso Mensual{" "}
                      <span className="text-danger">
                        <strong>* </strong>
                      </span>
                      <IconWithPopover1 />
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-pill"
                      id="ingresoMensual"
                      value={ingresoMensual}
                      onChange={(e) => setIngresoMensual(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="dependientes" className="mb-3 mt-4">
                      Dependientes{" "}
                      <span className="text-danger">
                        <strong>* </strong>
                      </span>
                      <IconWithPopover2 />
                    </label>
                    <input
                      type="text" // Change to text to handle input manually
                      className="form-control rounded-pill"
                      id="dependientes"
                      value={dependientes}
                      onChange={(e) => {
                        const val = e.target.value;
                        // Check if the input is not a number or if it's empty (to allow backspace to clear input)
                        if (!val || /^\d+$/.test(val)) {
                          setDependientes(val); // val is either empty or only contains digits
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="otrosIngresos" className="mb-3 mt-4">
                      Otros Ingresos{" "}
                      <span className="text-danger">
                        <strong> * </strong>
                      </span>
                      <IconWithPopover3 />{" "}
                    </label>
                    <Form.Select
                      className="form-control rounded-pill"
                      id="otrosIngresos"
                      value={otrosIngresos}
                      onChange={handleOtrosIngresosChange}
                    >
                      <option value="selecta">Seleccione una opción</option>
                      <option value="si">Si</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            {mostrarCamposOtrosIngresos && (
              <div className="row">
                <div className="col-md-4">
                  <div className="grid-item">
                    <div className="form-group">
                      <label htmlFor="montoOtrosIngresos" className="mb-3 mt-4">
                        Monto de Otros Ingresos{" "}
                        <span className="text-danger">
                          <strong> * </strong>
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control rounded-pill"
                        id="montoOtrosIngresos"
                        value={montoOtrosIngresos}
                        onChange={(e) => setMontoOtrosIngresos(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="grid-item">
                    <div className="form-group">
                      <label
                        htmlFor="origenOtrosIngresos"
                        className="mb-3 mt-4"
                      >
                        Origen de Otros Ingresos{" "}
                        <span className="text-danger">
                          <strong> * </strong>
                        </span>
                      </label>

                      <Form.Select
                        className="form-control rounded-pill"
                        id="origenOtrosIngresos"
                        value={origenOtrosIngresos}
                        onChange={(e) => setOrigenOtrosIngresos(e.target.value)}
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="TRABAJOS EXTRAS">TRABAJOS EXTRAS</option>
                        <option value="NEGOCIO PROPIO">NEGOCIO PROPIO</option>
                        <option value="ALQUILERES DE BIENES PROPIOS">
                          ALQUILERES DE BIENES PROPIOS
                        </option>
                        <option value="Opción 3">OTROS</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
                {/* Nuevo Campo */}
                <div className="col-md-4">
                  <div className="grid-item">
                    <div className="form-group">
                      <label
                        htmlFor="especifiqueOtrosIngresos"
                        className="mb-3 mt-4"
                      >
                        Especifique Otros Ingresos{" "}
                        <span className="text-danger">
                          <strong> * </strong>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        id="especifiqueOtrosIngresos"
                        value={especifiqueOtrosIngresos}
                        onChange={(e) =>
                          setEspecifiqueOtrosIngresos(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="gastosMensuales" className="mb-3 mt-4">
                      Gastos Mensuales{" "}
                      <span className="text-danger">
                        <strong> * </strong>
                      </span>
                      <IconWithPopover4 />{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-pill"
                      id="gastosMensuales"
                      value={gastosMensuales}
                      onChange={(e) => setGastosMensuales(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="fechaIngreso" className="mb-3 mt-4">
                      Fecha de Ingreso al Trabajo{" "}
                      <span className="text-danger">
                        <strong> * </strong>
                      </span>{" "}
                    </label>
                    <Calendario
                      value={selectedDate}
                      onChange={setSelectedDate}
                      minYearSet={1}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="profesion" className="mb-3 mt-4">
                      Profesión y ocupación{" "}
                      <span className="text-danger">
                        <strong> * </strong>
                      </span>{" "}
                    </label>
                    <Form.Select
                      className="form-control rounded-pill"
                      id="profesion"
                      value={profesion}
                      onChange={handleProfesion}
                    >
                      <option value="Select">Seleccione una opción</option>
                      {listaProfesiones.map((profesion) => (
                        <option
                          key={profesion.codigo_valor}
                          value={profesion.descripcion_valor}
                        >
                          {profesion.descripcion_valor}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="alto">
              <div className="d-flex flex-md-row-reverse row text-center mt-4">
                <div className="col-md-3 p-md-2 p-2 px-4">
                  {!areAllFieldsCompleted() ||
                  (otrosIngresos === "si" &&
                    (!montoOtrosIngresos ||
                      !origenOtrosIngresos ||
                      !especifiqueOtrosIngresos)) ? (
                    <button
                      className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                      disabled={true}
                    >
                      Siguiente
                    </button>
                  ) : !rechazo ? (
                    <button
                      type="submit"
                      className="w-100 btn btn-primary btn-lg rounded-pill
                      shadow text-white"
                    >
                      {" "}
                      Siguiente
                    </button>
                  ) : (
                    <button
                      onClick={manejarRechazo}
                      className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                    >
                      Siguiente
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="container mt-5">
          <div className="pt-5 text-center">Cargando...</div>
        </div>
      )}
    </div>
  );
}

export default StepFifteen;
