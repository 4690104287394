import React from "react";
import { Form } from "react-router-dom";
import Header from "../../Components/Header.jsx";

function Thirtythree() {
  return (
    <div>
      <Header textHeader="¡Gracias por completar tu solicitud!" />
      <div className="container">
        <div className="bg-white rounded m-5 p-5 mx-auto text-center">
          <h5>
            Agradecemos sinceramente tu confianza en Kivo Cash. Pronto, un
            Asesor de Préstamos se pondrá en contacto contigo para conocerte
            mejor y brindarte orientación en el proceso de obtener tu préstamo
            en línea.
          </h5>
        </div>
        <div className="alto">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Form action="/logout" method="post">
                <button
                  type="submit"
                  className="w-100 btn btn-danger btn-lg rounded-pill shadow"
                >
                  Salir
                </button>
              </Form>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thirtythree;
