import React from "react";
import Header from "../../Components/Header.jsx";
import "./Step2.css";
import { Form, useNavigate, useLoaderData } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

const StepTwo = () => {
  //actualizar paso siguiente
  const navigate = useNavigate();
  const Step = "/step4";
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };

  //manejo de token
  const token = useLoaderData();
  //console.log("token en paso 2:", token);

  // obtiene y muestra el nombre
  const nombres = localStorage.getItem("nombres");
  //console.log("datosche:", nombres);
  let userName = "Estimado Cliente";
  try {
    userName = nombres || userName; // Asumiendo que el token tiene un campo 'name'
  } catch (error) {
    console.error("Error de nombre");
  }

  return (
    <div>
      <Header textHeader={`Hola ${userName}`} />
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="p-4">
              <img
                src={require("./folks-young-woman-sitting-and-studying-the-map-1.png")}
                className="w-100"
                alt="welcome to kivo"
              />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="text-center bg-white rounded p-4 shadow w-100">
              <p>
              No olvides tener a mano tu carnet de identidad y los documentos que respalden 
              tus ingresos y deudas. Serán necesarios para enviar tu solicitud.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <button
                type="button"
                onClick={manejarClick}
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
              >
                Continuar
              </button>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Form action="/logout" method="post">
                <button
                  type="submit"
                  className="w-100 btn btn-danger btn-lg rounded-pill shadow"
                >
                  Salir
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
