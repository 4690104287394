export async function obtenerListaProfesiones() {
  try {
    const response = await fetch(
      "https://corsproxy.io/?https%3A%2F%2Fapi.kivocash.com%2Fcatalogos%2F1117"
      /*{
        method: "GET",
        mode: "cors",
        /*headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }*/
    );
    if (!response.ok) {
      throw new Error("Error al obtener la lista de profesiones");
    }

    const data = await response.json();
    console.log("result cors", data.listado_conceptos);

    // Filtrar la lista de profesiones
    const listaProfesiones = data.listado_conceptos.filter(
      (profesion) => profesion.estado === 0
    );

    console.log("lista", listaProfesiones);
    return listaProfesiones;
  } catch (error) {
    throw new Error("No se pudo cargar la lista de profesiones");
  }
}
