import React from "react";
import Header from "../../Components/Header.jsx";
import RouteGuard from "../../Components/NoGoBack.jsx";
import { useNavigate } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

function StepSeven() {
  const navigate = useNavigate();
  const Step = "/step8";
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  return (
    <div>
      <Header textHeader="Verificación de identidad" />
      <RouteGuard />
      <div className="container col-md-6 mx-auto text-center mt-md-5">
        <p className="text-center">
          <strong>
            Para verificar tu identidad, necesitamos que nos envíes fotos de
            ambos lados de tu carnet y también te tomes una selfie. ¡Gracias por
            tu colaboración! 😊
          </strong>
        </p>

        <img
          src={require("./folks-young-man-looks-at-his-watch-during-an-exam (1).png")}
          alt="young-man-looks-at-his-watch"
          className="img-fluid w-50"
        />
      </div>

      <div className="container mt-4">
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            <button
              type="submit"
              onClick={manejarClick}
              className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepSeven;
