import { Outlet, useLoaderData, useSubmit } from "react-router-dom";
import Logo from "../Components/Logo";
import NoGoBack from "../Components/NoGoBack";
import Redireccionador from "../Components/ReRounting";
import "../App.css";
import { useEffect } from "react";
import { getTokenDuration } from "./Login/auth";

function RootLayout() {
  getTokenDuration();
  
  return (
    <>
      <Logo />

      <div className="mainclass">
        <main>
          <Redireccionador />
          <Outlet />
        </main>
        <NoGoBack message="No puedes retroceder o cambiar la ruta manualmente." />
      </div>
    </>
  );
}
export default RootLayout;
