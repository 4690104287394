import React from "react";
import { Form } from "react-router-dom";
import Header from "../../Components/Header.jsx";

function Terminos() {
  return (
      <section class="relative pt-[40px] lg:pt-[80px] pb-[100px]">
        <div className="container">
            <div >
                <div style={{ textAlign: 'justify',  font: 'LemonMilk', margin: '20px' }}>
                    <h1 class="{{styles.h4}} mb-16 " style={{ textAlign: 'center' }}>
                    TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA KIVO DE GENCORP S.R.L.<br/>
                    (“Términos y Condiciones” o “T&C”)
                    </h1>
                <p class="{{styles.paragraphRegular}} " style={{ textAlign: 'justify' }}>
                    Al acceder y/o utilizar la Aplicación Móvil o Sitio Web de KIVO, el Usuario acepta que estos Términos y Condiciones contractualmente son el único régimen que regula la relación entre el Usuario y Gencorp cuando éste realice las siguientes acciones: i) utilice el sitio y/o la aplicación KIVO, ii) solicite y/o reciba un préstamo de dinero (“Préstamo”) de Gencorp, en tanto no se haya suscrito un contrato definitivo de préstamo iii) utilice cualquier otro servicio brindado a través del sitio o la aplicación KIVO (los “Servicios").
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">I. Generalidades, definiciones</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>1.1.</b> GENCORP S.R.L. (en adelante simplemente “Gencorp”) es una sociedad comercial legalmente constituida bajo las leyes del Estado Plurinacional de Bolivia, con matrícula de comercio y Número de Identificación Tributaria 455158022; con domicilio en La Paz, Bolivia. En la Av. Landaeta, Edificio Qhana Nº 522 Mezanine of. 10. Gencorp tiene como objeto social el otorgar a sus clientes préstamos de dinero otorgados con fondos propios (sin que exista captación o intermediación financiera) mediante plataformas tecnológicas, a cambio de un interés previamente acordado y aceptado por el Usuario. El Usuario declara que conoce el objeto de Gencorp, las operaciones que brinda y su naturaleza fuera del régimen bancario-financiero. 
                </p>
                <p class="{{styles.paragraphRegular}}">
                    La plataforma KIVO de propiedad de Gencorp (en adelante simplemente “Plataforma” o “KIVO”) es el medio tecnológico por el cual el Usuario solicitará las operaciones de préstamo a Gencorp, así como administrar sus operaciones, realizar solicitudes, evaluar operaciones y otras acciones permitidas en la Plataforma. Esta Plataforma puede ser mediante acceso web (“Sitio Web”) o un aplicativo descargable al teléfono móvil del Usuario (“Aplicación”). Kivo y Gencorp son, además, marcas registradas o en proceso de registro y su titular es Gencorp.  Los programas, bases de datos, redes y archivos que permiten al Usuario acceder al Sitio Web o Aplicación están protegidos por las leyes nacionales de Bolivia y tratados internacionales de protección a los derechos de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>1.2.</b>  “Usuario”, “Cliente” y/o “Usted” a efectos de los T&C son denominaciones que, indistintamente e independientemente, hacen referencia a su persona, una persona natural que declara tener plena capacidad de obrar para poder formar contratos de préstamo de dinero y aceptar estos T&C sin que exista algún vicio en su consentimiento, error, dolo o violencia.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">II. Aspectos generales. Acceso al Sitio Web y Aplicación KIVO.</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.1.</b> En caso que el Usuario no esté de acuerdo o no acepte los Términos y Condiciones no podrá utilizar KIVO y consecuentemente no se formará una relación jurídica entre Gencorp y el Usuario. En dicho caso, el Usuario no deberá compartir información suya con Gencorp. 
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.2.</b> El Usuario declara que antes de aceptar los Términos y Condiciones ha tomado pleno conocimiento de los mismos, ha podido acceder a ellos al ingresar al Sitio Web como así también pudo leerlos y/o descargarlos durante el proceso de solicitud por la Aplicación o Sitio Web, cuenta con copia de ellos a su disposición, pudiendo: guardarlos en su ordenador, imprimirlos o retirar copia firmada por Gencorp en el domicilio especial fijado por la misma tras una solicitud escrita dirigida a Gencorp.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.3.</b> El Usuario reconoce y acepta que los Términos y Condiciones se convienen sobre la base de las disposiciones contenidas en el Código Civil y Código de Comercio de Bolivia, y demás normativa conexa y concordante en Bolivia que verse sobre la formación de contratos y accesoriamente sobre la protección de datos personales. Asimismo, las Partes reconocen que por la naturaleza no bancario-financiera de Gencorp y de las operaciones que realiza, no le es aplicable a esta relación jurídica la normativa específica para entidades de intermediación financiera, bancos u otras de igual o similar naturaleza.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.4.</b> El Usuario reconoce y acepta que su relación contractual con Gencorp, a través del Sitio Web y/o la Aplicación es posible por la implementación de Tecnologías de la información y comunicación que permiten brindar los servicios de Gencorp y por lo tanto reconoce y acepta de manera expresa la utilización de medios electrónicos para la creación de la relación jurídica entre el Usuario y Gencorp mediante estos T&C sin el requerimiento de un soporte en papel. Asimismo, el Usuario reconoce que toda comunicación que se suscite de estos T&C se darán al correo electrónico que el Usuario haya utilizado para acceder a ellos mediante el Sitio Web o Aplicación o mediante notificaciones push de la Aplicación o Sitio Web.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.5.</b> El Usuario declara contar con un soporte electrónico y/o tecnología y conexión a internet adecuada y necesaria para realizar las operaciones ofrecidas por Gencorp por intermedio del Sitio Web y Aplicación, lo cual es entera responsabilidad del Usuario.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.6.</b> Quien accede al Sitio Web y/o Aplicación y desea registrarse en ellos para utilizar los Servicios ha seguido los pasos disponibilizados electrónicamente para acceder, visualizar y conocer estos Términos y Condiciones y estando en pleno conocimiento, acepta expresamente todas las cláusulas aquí establecidas como condición para el ingreso y utilización del Sitio Web y la Aplicación, sin la necesidad de una firma propiamente sino con el simple marcado de una casilla y posterior clic en un botón de aceptación en un ordenador o equipo móvil; o por el sólo hecho de usar KIVO, el Sitio Web o la Aplicación.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.7.</b> Solamente se permite la aceptación de los T&C a personas naturales – físicas que i) sean mayores de dieciocho (18) años, y ii) tengan capacidad de obrar para contratar según las leyes del Estado Plurinacional de Bolivia. No podrán aceptar estos T&C personas colectivas – jurídicas, los menores de edad o personas naturales incapacitadas para contratar.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.8.</b> Gencorp se reserva el derecho de modificar en cualquier momento los Términos y Condiciones de acuerdo a sus necesidades y las condiciones del mercado. Dichas modificaciones estarán disponibles en cada caso en el Sitio Web y/o la Aplicación y entrarán en vigencia a partir de su recepción vía email y/o notificación push dentro de la Aplicación o Sitio Web.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.9.</b> Las disposiciones de los T&C solamente regulan la relación entre el Usuario y Gencorp para dar inicio a la operación definitiva de Préstamo que el Usuario eventualmente podrá solicitar. Consecuentemente, los T&C, su aceptación o el solo uso de la Aplicación o Sitio Web no implican la aprobación de un Préstamo por Gencorp o alguna obligación adicional a aquellas contenidas en este documento. El Usuario acepta que para la obtención de cualquier Préstamo, servicio o desembolso a ser eventualmente provisto por Gencorp, deberá haberse llevado a cabo el procedimiento previo de aprobación y la formación del contrato correspondiente que refleje las condiciones finales acordadas para la operación que corresponda. Las Partes aclaran que nada de lo contenido en los T&C implican una obligación de ellas individual o conjuntamente de formar los contratos de referencia, y ellos serán tratados de forma posterior con el cumplimiento de los requisitos fijados por la normativa aplicable a tal fin.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.10.</b> Gencorp podrá continuar, discontinuar o cambiar cualquier producto o servicio ofrecido en el Sitio Web y la Aplicación cuando así lo vea necesario y sin necesidad de justificación frente al Usuario. Asimismo, se reserva el derecho de cambiar, mejorar o corregir la información existente, los contenidos, las descripciones y explicaciones en dichas plataformas, y suspender y/o negar su acceso por razones de mantenimiento (programado o no programado). En caso que un Préstamo o servicio esté concertado habiéndose cumplido las condiciones referidas en el numeral anterior y las modificaciones incidan relevantemente en las condiciones en que el mismo fue otorgado, éstas serán informadas al Usuario a la mayor brevedad posible y se concretarán de acuerdo a las disposiciones que le sean aplicables.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>2.11.</b> La Aplicación KIVO es de propiedad de Gencorp siendo Usted únicamente usuario de los servicios que son ofrecidos mediante ella. El uso de la Aplicación por el Usuario sólo será con fines personales y no será usada con fines comerciales o financieros, ni siquiera luego de haberse concluido alguna operación de préstamo. En caso de uso contrario a lo dispuesto por esta sección o por los T&C, Gencorp quedará facultada para inhabilitar la aplicación para el Usuario.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">III. Relación jurídica entre las Partes, obligaciones y autorización de uso/verificación de información provista por el Usuario</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.1.</b> Las partes acuerdan que los Términos y Condiciones y su aceptación por parte del Usuario vía electrónica o informática son un compromiso válido y legal entre el Usuario y Gencorp, en especial al conocimiento de las actividades y condiciones que utiliza Gencorp para el otorgamiento de préstamos y la entrega de información del Usuario para la evaluación de riesgos para la decisión de otorgamiento de dichos préstamos. En relación a los Préstamos o servicios que el Usuario solicite a Gencorp con posterioridad, ellos serán manejados como se señala en el numeral 2.9. anterior.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.2.</b> El Usuario que desee acceder a los Préstamos de Gencorp deberá completar los datos necesarios para realizar una solicitud u operar con algunos de los Servicios del Sitio Web y la Aplicación. Esta información es, de forma enunciativa: su Cédula de Identidad vigente, Nombres y Apellidos, Dirección de Domicilio y Laboral, número de teléfono celular o móvil, Factura de Luz o aviso de cobranza, extractos de aportes a AFPs o cualquier entidad gestora de aportes patronales dependientes, autorización para información crediticia, fotografías, declaraciones juradas patrimoniales, y otros que serán requeridos por Gencorp al momento de la solicitud por medio del Sitio Web o la Aplicación. Dichos datos tendrán el tratamiento especificado en el apartado VI del presente documento.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.3.</b> Cuando los usuarios accedan por primera vez a la Aplicación deberán ingresar mediante una dirección correo electrónico que lo identifique. Dicha dirección será el mecanismo elegido por el Usuario para recibir toda comunicación emergente de los T&C.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.4.</b> El Usuario autoriza a Gencorp a verificar la existencia y tenencia del número celular indicado y/o correo electrónico del Usuario mediante el envío de un PIN vía SMS o mensaje de validación de su cuenta de correo electrónico que luego el usuario deberá ingresar manualmente en la aplicación, siempre que la funcionalidad de los sistemas de comunicación así se lo permitan, a fin de que éste proceda a validar correo electrónico.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.5.</b> Siguiendo el procedimiento indicado en el Sitio Web y la Aplicación, el Usuario podrá activar su “Cuenta del Usuario”. Una vez activada la Cuenta del Usuario, éste tendrá acceso a la aplicación y/o los servicios del Sitio Web y la Aplicación mediante el uso del mismo dispositivo móvil y línea en la que realizó el registro, y de la clave de acceso que el usuario define a su propia elección y responsabilidad. En caso de cambiar de dispositivo móvil deberá bajar nuevamente la aplicación y cargar sus datos actualizados.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.6.</b> El Usuario podrá modificar su contraseña de acceso en cualquier momento y se obliga a preservar tal contraseña bajo absoluta confidencialidad y a no revelarla o compartirla con otras personas bajo cualquier concepto o circunstancia. El Usuario es exclusivo responsable por el uso y resguardo de la contraseña de acceso al Sitio Web y la Aplicación y asume toda consecuencia derivada de ello. 
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.7.</b> Gencorp, con conocimiento y aceptación del Usuario, queda facultada para rechazar el pedido de activación, así como el de cancelar una activación previamente aceptada, en caso de que considere que existan razones suficientes para ello (como ser de seguridad u otros), así como cuando considere que el Usuario en un primer momento de la solicitud del Préstamo, o de forma sobreviniente a éste, no cumpla con los requisitos mínimos establecidos en los numerales 2.5 y/o 2.7. anteriores.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.8.</b> El Usuario entiende que el Sitio Web y la Aplicación es un sistema informático y que, como cualquier creación humana, dependiente de sistemas de tecnología y telecomunicaciones, no está exenta de errores. En este sentido, KIVO no puede garantizar que el Sitio Web y la Aplicación funcionará sin errores (bugs).
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.9.</b> Gencorp no garantiza el acceso, uso continuado o ininterrumpido del Sitio y la Aplicación. El Usuario entiende que el Sitio Web y la Aplicación pueden no estar disponibles debido a dificultades técnicas o fallas de Internet o por cualquier otra circunstancia, incluso ajena a la responsabilidad de Gencorp, por lo que en ningún caso Gencorp será responsable por las demoras y/o problemas de cualquier especie que surjan.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.10.</b> No obstante, Gencorp se compromete a realizar sus mejores esfuerzos para procurar restablecer el sistema con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. <br/>El Usuario declara que los datos proporcionados corresponden a sus datos de identificación propios y son verdaderos. El Usuario garantiza y responderá en cualquier caso por la veracidad, exactitud, vigencia y autenticidad de los datos facilitados y se compromete a mantenerlos debidamente actualizados.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.11.</b> Al aceptar los T&C, el Usuario autoriza a Gencorp a confirmar los datos suministrados u otros que permitan realizar una evaluación crediticia, acudiendo a entidades públicas, compañías especializadas del sector privado o centrales de riesgo, burós de información crediticia, bancos o cualquier otro medio de confirmación, registros públicos, como así también evaluar atributos de comportamiento crediticio. La información que Gencorp obtenga de estas consultas, así como la proporcionada por el usuario, será tratada en forma confidencial y empleada exclusivamente para evaluar la solicitud del Préstamo recibida. Dichos datos recibirán el tratamiento especificado en el apartado VII.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.12.</b> Expresamente el Usuario presta conformidad para que Gencorp le pueda informar del otorgamiento de cada préstamo, sus condiciones de cumplimiento, mora, cualquier incumplimiento de condiciones convenidas, entre otros y todo dato personal puede, acorde a la ley vigente, ser otorgado a las bases de datos de las distintas centrales de riesgo u entidades de regulación del Estado y los burós de información crediticia, en tanto haya sido aprobado siguiendo el procedimiento interno de Gencorp y se haya cumplido con la condición fijada en el numeral 2.9. anterior.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>3.13.</b> Gencorp podrá solicitar al Usuario la presentación o envío de cualquier comprobante legal, identificación personal emitida por autoridad competente vigente a la fecha y/o dato adicional que considere necesario a efectos de confirmar y/o verificar sus datos personales.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">IV. Solicitud de Servicios de Préstamos.</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.1.</b> El Usuario, luego de ingresar a su cuenta en el Sitio Web y la Aplicación, podrá solicitar a Gencorp, en todo momento, el otorgamiento de un préstamo (“Préstamo”) indicando i) el monto de la misma, ii) el plazo de devolución que propone y iii) la cuenta bancaria en la que se acreditará el Préstamo respectivamente (la “Solicitud”). En caso de no poseer una cuenta, Gencorp podrá considerar habilitar otros canales para el desembolso y pago del Préstamo que serán oportunamente informadas al Usuario.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.2.</b> Una vez recibida la Solicitud, en un plazo no mayor a 2 días hábiles, en el caso de préstamos para personas independientes y 24 horas para personas asalariadas siempre y cuando el usuario haya enviado todos los requisitos requeridos por tipo de préstamo. Gencorp comunicará la decisión de aprobación o rechazo al Usuario. En este caso, informará mediante notificación push a la Aplicación, llamada telefónica o mediante correo electrónico: i) el monto del Préstamo susceptible de ser acordado, ii) el monto y las fechas de vencimiento de las cuotas del Préstamo, y iii) los términos y condiciones del crédito para ser aceptados por el cliente.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.3.</b> KIVO se reserva el derecho de rechazar la Solicitud de Préstamo efectuada por un Usuario cuando no cumpla con los requisitos establecidos por KIVO para otorgar un Préstamo:
                </p>
                <ul class="listado_politicas">
                    <li>
                        Relación laboral no acreditada.
                    </li>
                    <li>
                        Ingresos declarados mínimos según declaración oficial.
                    </li>
                    <li>
                        Contrato de trabajo, u otros no disponibles.
                    </li>
                    <li>
                        No poseer antecedentes crediticios favorables.
                    </li>
                    <li>
                        Sobreendeudamiento en el reporte de BIC (Buro de Información Crediticia).
                    </li>
                    <li>
                        Entre otros que KIVO vea conveniente
                    </li>
                </ul>
                <p class="{{styles.paragraphRegular}}">
                    De igual manera, por motivos de seguridad KIVO se reserva el derecho de solicitar al Usuario la realización de un nuevo procedimiento de acreditación de identidad para cada Solicitud de Préstamo que fuera a realizar.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.4.</b> El Usuario, una vez recibida, procesada su Solicitud de Préstamo y aprobada ésta, recibirá la “Propuesta de Préstamo”, dentro del Sitio Web y la Aplicación, la cual desplegará una pantalla que reza entre otros términos: “Tu solicitud fue aprobada. Por favor acepta los términos y condiciones del préstamo” así como el “monto aprobado” y la “cantidad de cuotas” con su valor respectivo. En dicha pantalla el Usuario lee detenidamente y acepta los montos desplegados, así como los presentes Términos y Condiciones del Crédito y/o el “Contrato de Préstamo” de plena voluntad y conformidad suya, al hacer clic y/o presionar los botones: “Declaro que leí y acepto los términos y condiciones de crédito” así como el botón “CONTINUAR”, momento en el cual queda perfeccionado el Contrato de Préstamo que incluirá toda la información aquí descrita y datos proveídos por el Usuario.						
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.5.</b> La Aceptación obligará a KIVO a liquidar en un plazo no mayor a 24 horas en el caso de préstamos para asalariados y 2 días hábiles para préstamos para independientes, el Préstamo en las condiciones detalladas en la Propuesta de Préstamo. Siempre y cuando el usuario cumpla con la presentación y verificación de todos los requisitos solicitados.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.6.</b> KIVO enviará a un correo electrónico verificado del cliente copia de estos términos y condiciones y Contrato de Préstamo junto con el préstamo liquidado, cantidad y montos de cuota a abonar junto con las fechas de vencimiento.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.7.</b> El Usuario entiende y reconoce que la Solicitud y la Aceptación del Préstamo, implica la íntegra aceptación de los presentes Términos y Condiciones.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.8.</b> KIVO, en todo momento, cumplirá con las normas fiscales vigentes del estado plurinacional de Bolivia, a cuyos fines procederá a emitir las facturas que correspondan al caso. Ante cualquier situación no equivalente a esta cláusula, KIVO buscará la inmediata rectificación de la circunstancia acaecida por sí mismo o por notificación del Usuario.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.9.</b> El Usuario podrá revocar la aceptación de la Propuesta del Préstamo para asalariados dentro las 24 horas y para préstamos para independientes y/o cualquier otro servicio que se le haya otorgado desde el Sitio Web y la Aplicación dentro de los dos (2) días hábiles computados a partir de la aceptación de la misma. Para efectuar su derecho de revocación, el Usuario deberá notificar a KIVO su decisión por escrito o medios electrónicos o similares, o mediante la devolución del dinero del Préstamo y/o del servicio solicitado dentro del plazo de dos (2) días hábiles; en cuyo caso se deberán los intereses y gastos que correspondieran.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.10.</b> El Sitio Web y la Aplicación podrán ofrecer la financiación de productos y servicios, incluyendo, pero no limitándose a los siguientes: motos, bicicletas, materiales para la construcción y/o mejora del hogar, electrodomésticos, viajes entre otros. Esta operatoria se realizará en acuerdo con empresas dedicadas y especializadas en dicho rubro (partners), que ofrecerán la financiación de KIVO como una de las formas de acceder a dichos productos o servicios. En estos casos el cliente podrá realizar dichas operaciones tanto de manera presencial en alguno de dichos comercios como de forma remota a través de los sitios de comercio electrónico de los partners. Los partners podrán utilizar folletería y/o campañas en medios digitales para promover estas alianzas comerciales. El cliente en estos casos realizará una solicitud de préstamo, con la diferencia que el monto de la financiación será transferido al Comercio que realiza la venta financiada. En el caso previsto en la presente cláusula de financiación de productos y/o servicios ofrecidos por terceros, el cliente autoriza e instruye expresamente a KIVO por el presente, a realizar dicha acreditación previa verificación de KIVO de la entrega del producto o contrato de servicio al cliente. Dicha entrega quedará confirmada con la firma del cliente en la factura o recibo correspondiente. La forma de pago de cuotas de estos préstamos es la misma que para los préstamos con acreditación en cuenta del cliente.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.11.</b> KIVO podrá contratar servicios de proveedores de comercialización de préstamos, quienes tendrán la tarea de realizar convenios con comercios que participen del sistema de préstamos.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.12.</b> KIVO contrata servicios de comercializadores quienes se ocuparán de reunir documentación y verificar la oferta para El Usuario solicitante.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>4.13.</b> En caso de duda de algún cliente respecto de si un comercializador está autorizado por KIVO ponemos a disposición el WhatsApp +591 777-04043 y el correo electrónico marketing@gencorpbo.com.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">V. Precio de los Servicios</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>5.1.</b> El Usuario al comunicar la Aceptación de la Propuesta de Préstamo, y una vez que el Préstamo se haya acreditado en su cuenta bancaria o en el método de acreditación que mediante la aplicación o sitio web KIVO se haya pactado entre las partes, reconoce y acepta que deberá a KIVO la suma que éste indique en el contrato de Préstamo.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">VI. Pago de las cuotas del préstamo</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.1.</b> El Usuario, en este acto, mediante la aceptación de los Términos y Condiciones Generales y del Contrato de Préstamo, se compromete al pago de la totalidad de las cuotas del préstamo.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.2.</b> El Usuario abonará en las fechas pautadas y por los montos confirmados por él mismo en la aplicación al aceptar la propuesta de préstamo los siguientes conceptos: el capital y la tasa de interés.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.3.</b> El pago de las cuotas se podrá cursar, entre otros, mediante los siguientes medios: a- Una tarjeta de débito del usuario. b- En las oficinas y sucursales de KIVO, Pago móvil, Home Banking, Billetera electrónica, Giros, entre otros. E- Transferencias Bancarias, mediante Débito directo del recibo de sueldo en caso de convenio entre su empleador y/o comercio con KIVO, algún otro medio disponible. Según lo indicado por el Usuario mediante la aplicación y/o sitio KIVO.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.4.</b> El Usuario se compromete a notificar a KIVO ante un cambio en la cuenta bancaria informada o método de pago registrado en la aplicación con el que opera habitualmente. Dicha notificación deberá ser de manera fehaciente y con la suficiente antelación a la fecha correspondiente al próximo vencimiento.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.5.</b> En caso de que, en cualquier momento durante la vigencia del Préstamo, por cualquier circunstancia, el Usuario incumpliere con el pago íntegro de cualesquiera de las Cuotas en los términos convenidos y/o abonare una o varias Cuotas sólo parcialmente, KIVO imputará la cantidad de intereses compensatorios por los días de pagados de acuerdo a su plan de pagos.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>6.6.</b> KIVO podrá, a su solo criterio, otorgar al Usuario, ante un pedido de éste, una extensión del plazo para el repago del monto adeudado del Préstamo con las formalidades que establezca y previa información y aceptación de las condiciones por parte del Usuario.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">VII. Tratamiento de Datos Personales</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.1.</b> Se considerará que <b>i)</b> el acceso de cualquier visitante al Sitio Web y la Aplicación, en forma ocasional o frecuente, <b>ii)</b> la utilización de alguno de los servicios prestados por el Sitio Web o la Aplicación, o <b>iii)</b> su registro en el Sitio Web y la Aplicación; implica la aceptación de estos Términos y Condiciones y son de carácter vinculante para todos los Usuarios o personas que han desplegado las conductas descritas en esta cláusula.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.2.</b> El Usuario declara y manifiesta con relación a sus datos personales, es decir, toda información suministrada a Gencorp y recabada por ésta, vinculada al servicio objeto de la presente (los “Datos Personales”), que otorga su consentimiento para que: <b>i)</b> los mismos sean almacenados y utilizados para la consideración de cualquier Préstamo y/o servicio que pueda solicitar a Gencorp en cualquier momento dado, y para cualquier ofrecimiento que Gencorp pueda efectuarle en el futuro; o <b>ii)</b> Gencorp pueda informar su situación de pago a los burós de información crediticia.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.3.</b> Los Datos Personales solicitados en la Aplicación y/o el Sitio Web resultan indispensables para la evaluación de los Préstamos, por lo cual deben ser obligatoriamente aportados por el Usuario en forma exacta, veraz y actualizada. Adicionalmente, Gencorp podrá solicitar al Usuario por cualquier canal de comunicación toda la información que considere necesaria para la corroboración de datos manifestada por el cliente desde la Aplicación.<br/>Con cada interacción con la Aplicación o Sitio Web, se registran ciertos tipos de información como ser fecha y hora de acceso, las pantallas consultadas e ingresos de datos realizados. Utilizamos cookies para recabar información cuando mediante la aplicación móvil kivo o su navegador de Internet accede a www.kivocash.com. Toda esta información captada es de conocimiento y aceptación por parte del Usuario.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.4.</b> El Usuario, titular de los Datos Personales, autoriza a Gencorp para la conservación, procesamiento y transferencia de los Datos cuando éstos sean requeridos por otras entidades financieras, organismos públicos y/o autoridades judiciales autorizadas por normativa vigente; y en los casos en los que lo requiera u autoricen las leyes aplicables.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.5.</b> Asimismo, el Usuario toma conocimiento y autoriza a Gencorp expresamente a recabar todos los datos crediticios y/o bancarios del Usuario de bases de datos públicas, privadas y/o de empresas especializadas (a saber: cantidad de cuenta/s, tipo de cuenta/s y banco/s donde esté/n abierta/s la/s misma/s, empréstitos, compras financiadas efectuadas y todos los datos vinculados a éste tipo de transacciones financieras, entre otros) que considere necesarios para analizar o efectivizar el otorgamiento o el cobro de un Préstamo.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>7.6.</b> Gencorp atenderá las consultas de acceso a la información personal vertidas por los Usuarios. El derecho a la rectificación, actualización o supresión de los datos personales inexactos o incompletos es sin costo alguno para el Usuario, y sólo requerirá la solicitud correspondiente más la exhibición del documento de respaldo para realizar la rectificación, actualización o supresión que se solicite.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">VIII. Cláusulas adicionales</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>8.1.</b> El Usuario declara haber sido informado por Gencorp que el Sitio Web y la Aplicación son de acceso voluntario por él, sujeto a los Términos y Condiciones para el uso y/o goce de los servicios que Gencorp contiene y brinda. En consecuencia, Gencorp no asume cualquier responsabilidad adicional a las que se fijan específicamente en los Términos y Condiciones.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>8.2.</b> El Usuario, a tiempo de aceptar los Términos y Condiciones, otorga su pleno consentimiento a Gencorp para que a través del Sitio Web o la Aplicación se utilicen los datos para enviar información, promociones y/o publicidad. El Usuario, en cualquier momento futuro, podrá solicitar el no envío de material promocional o publicitario. El Usuario deja claramente establecido que no atribuirá cualquier tipo de responsabilidad o reclamo a Gencorp por la publicidad de terceros.
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>8.3.</b> El uso del Sitio y la Aplicación por el Usuario es gratuito.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">IX. Notificaciones. Domicilio especial</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>9.1.</b> En tanto no se modifique en el futuro, el Usuario acepta la plena validez de las notificaciones recibidas de Gencorp a través del Sitio Web y la Aplicación y/o por correo electrónico (en la dirección comunicada por el Usuario).
                </p>
                <p class="{{styles.paragraphRegular}}">
                    <b>9.2.</b> Cualquier comunicación del Usuario a Gencorp deberá realizarse por escrito en el domicilio especial ubicado en la Av. Landaeta, Edificio Qhana Nº 522, mezanine Of. 10, zona San Pedro de la ciudad de La Paz, Bolivia.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">X. Horario de Atención</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>10.1.</b> La plataforma está disponible para los usuarios las 24 horas del día, los 365 días del año para realizar operaciones y adquirir servicios en la misma. Los horarios de atención en oficinas a clientes son los siguientes: lunes a viernes 9:00 a 18:00 y sábado 9:00 a 12:00 h.
                </p>

                <h3 class="{{styles.h6}} mt-10 mb-0">XI. Cláusula Arbitral</h3>
                <p class="{{styles.paragraphRegular}}">
                    <b>11.1.</b> Las Partes expresan que todo litigio, discrepancia, cuestión o reclamación resultante de la ejecución o interpretación de los T&C o relacionado con ellos directa o indirectamente, se someterá previamente a la negociación directa entre Partes. <br/>Si agotada la negociación entre Partes o expirado el plazo máximo de 10 (diez) días calendario, la controversia no fuese resuelta amigablemente, la misma se resolverá definitivamente mediante arbitraje en el marco de la Ley No. 708 de Arbitraje y Conciliación de 25 de junio de 2015. <br/>El arbitraje será sometido ante el Centro de Conciliación y Arbitraje de la Cámara Nacional de Comercio de la ciudad de La Paz, bajo sus reglamentos y condiciones, renunciando ambas Partes a someter la disputa ante cualquier otra entidad. Las Partes hacen constar expresamente su compromiso de cumplir el Laudo Arbitral que se dicte.
                </p>
            </div>
        </div>
    </div>
</section>
   
    
  );
}

export default Terminos;
