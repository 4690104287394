import React, { useState } from 'react'
import Header from "../../Components/Header.jsx";
import './Encuesta.css'
import { useNavigate } from 'react-router-dom';

export default function InicioEncuesta() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nombre_encuesta: '',
    apellido_encuesta: '',
  });

  // Función para manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    // Guardar los datos en localStorage
    localStorage.setItem('formData', JSON.stringify(formData));
    console.log('Datos del formulario guardados en localStorage:', formData);
    navigate('/encuesta');
  };

  return (
    <div className='container'>
        <Header textHeader="Bienvenid@!!!"/>
        <form onSubmit={handleSubmit}>
        <div className='anchos shadow rounded-4 mb-12 col-sm-12' style={{color:"#2C3F81"}}>
            <h4>Introduce tus datos</h4>
        <div className="row">
            
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Nombre <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="nombre_encuesta"
                  className="form-control"
                  placeholder="Nombre"
                  //value={formData.nombre_encuesta}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Primer Apellido<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="apellido_encuesta"
                  className="form-control"
                  placeholder="Primer Apellido"
                  //value={formData.apellido_encuesta}
                  onChange={handleChange}
                  required
                />
              </div>
            
          </div>
        </div>
        <div className='container col-xl-4'>   
                <button
                    className="btn btn-primary mt-2 w-100"
                    type="submit"
                    style={{color:"white", font:'LemonMilk', fontSize:"20px"}}
                >
                    Siguiente
                </button>
        </div>
        </form> 
    </div>
    
  )
}
