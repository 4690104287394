import React from "react";
import { Popover, OverlayTrigger, ButtonToolbar } from "react-bootstrap";

function IconWithPopover1() {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p className="m-4">Ingresa tu salario neto</p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popoverTop}
      >
        <i className="bi bi-question-circle-fill text-primary"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}

function IconWithPopover2() {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p className="m-4">
        Cantidad de hijos o personas que dependen económicamente de ti
      </p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popoverTop}
      >
        <i className="bi bi-question-circle-fill text-primary"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}
function IconWithPopover3() {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p className="m-4">
        Si obtiene ingresos adicionales provenientes de consultorías, clases o
        emprendimientos propios u otros, por favor indique el monto percibido.
      </p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popoverTop}
      >
        <i className="bi bi-question-circle-fill text-primary"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}
function IconWithPopover4() {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p className="m-4">
        Total de gastos familiares, de alimentación, alquileres, etc.
      </p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popoverTop}
      >
        <i className="bi bi-question-circle-fill text-primary"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}
export {
  IconWithPopover1,
  IconWithPopover2,
  IconWithPopover3,
  IconWithPopover4,
};
