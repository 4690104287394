import React, { useState } from "react";
import Header from "../../Components/Header.jsx";
import "./step4.css";
import { Form, useLoaderData, useNavigate } from "react-router-dom";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import Redireccionador from "../../Components/ReRounting";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

const StepFour = () => {
  const token = useLoaderData();
  const id = localStorage.getItem("id");
  const [error, setError] = useState(null);
  const [errorUpdate, setErrorUpdate] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const clearError = () => setError(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Step = "/step5";
  const manejarClick = () => {
    handleUpdate(Step);
    navigate(Step);
  };

  const mostrarAlerta = () => {
    setErrorUpdate(true);
    setAlertVisible(true);
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_BACKEND}/system/usuarios/validacion-datos/${id}`;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        console.log("test1", JSON.stringify(data.datos.id));
        localStorage.setItem("idSolicitud", data.datos.id);
        manejarClick();
      } else {
        const data = await response.json();
        if (data.mensaje === "Revise sus datos y vuelva a intentarlo") {
          //mostrarAlerta(); // esto muestra una pantalla con dos botones

          handleUpdate("/profile");
          console.log("se hizo el handle");
          navigate("/profile");
        }
        console.log("datos incorrectos", data.mensaje);
        setError({
          message: data.mensaje,
          type: "danger",
        });
        setLoading(false);

        // navigate("/profile");
      }
    } catch (error) {
      console.log("Verification failed! ", error);
      setError({
        message: "Ocurrió un error inesperado, intente de nuevo",
        type: "danger",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={alertVisible ? "modal-overlay" : ""}>
        <center>
          <Header textHeader="Productos Kivo" />
          <Redireccionador />
          <ErrorHandling error={error} clearError={clearError} />
          <h3 className="text-muted p-2">
            Selecciona el producto que mejor se adapte a tus necesidades
          </h3>
        </center>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-4 ">
              <div className="bg-movil rounded-4 p-2 shadow d-flex flex-column justify-content-center">
                <center>
                  <img
                    src={require("./folks-woman-preparing-startup-rocket-to-launch-with-ideas-1.png")}
                    className="w-50 m-3"
                    alt="woman-preparing-startup-rocket"
                  />
                </center>
                <div>
                  <h3 className="text-center">Independiente</h3>
                  
                  <p>Te esperamos en la ciudad de La Paz para que puedas entrevistarte 
                    con uno de nuestros Asesores de Préstamos </p>
                    <a href="https://kivocash.com/contacto">Ver direccion y horarios de atención</a>
                </div>
              </div>
            </div>

            <div className="col-md-4 rounded">
              <div className="bg-movil rounded-4 p-2 shadow d-flex flex-column justify-content-center">
                <center>
                  <img
                    src={require("./folks-young-man-looks-at-his-watch-during-an-exam.png")}
                    className="w-75"
                    alt="young-man-looks-at-his-watch"
                  />
                </center>
                <div>
                  <h3 className="text-center">Asalariado</h3>
                  <ul>
                    <li>CI vigente</li>
                    <li>Factura de luz</li>
                    <li>3 últimas boletas de pago o contrato de trabajo</li>
                    <li>
                      Certificado de la gestora o extracto bancario de los
                      últimos 3 meses
                    </li>
                  </ul>
                </div>
                <div>
                  <button
                    type="button"
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow"
                    onClick={handleSubmit}
                  >
                    {!loading ? (
                      "Elegir"
                    ) : (
                      <span className="text-white">Cargando...</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg-movil rounded-4 p-2 shadow d-flex flex-column justify-content-center">
                <center>
                  <img
                    src={require("./folks-laptop-wide-open.png")}
                    className="w-75"
                    alt="laptop-wide-open"
                  />
                </center>
                <div>
                  <h3 className="text-center">E-commerce</h3>
                  <p className="text-center py-4">Proximamente...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorUpdate && (
        <div
          className="row alert bg-danger alert-dismissible fade show text-white "
          role="alert"
        >
          {errorUpdate.message}
          <strong className="text-center">Tus Datos no son correctos</strong>
          <div className="row pt-3">
            <div className="col">
              <button
                type="button"
                className="fs-6 w-100 btn btn-danger btn-lg rounded-pill shadow mb-2"
                onClick={() => {
                  handleUpdate("/profile");
                  navigate("/profile");
                }}
              >
                Actualizar Datos
              </button>
            </div>
            <div className="col">
              <Form action="/logout" method="post">
                <button
                  type="submit"
                  className="fs-6 w-100 btn btn-danger btn-lg rounded-pill shadow mb-2"
                >
                  Salir
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepFour;
