import { Link } from "react-router-dom";
import Header from "../../Components/Header.jsx";
import React from "react";
import "./step25.css";

const StepTwentyfive = () => {
  return (
    <div>
      <div>
        <div>
          <center>
            <Header textHeader="¡Importante!" />
          </center>
        </div>

        <div className="container pt-4">
          <div className="row ">
            <div className="col-md-6 d-flex justify-content-center">
              <div className="anchocuadro">
                <div className="text-center bg-white rounded p-4 shadow">
                  <p>
                    Para continuar con tu solicitud de préstamo debes tener a la
                    mano lo siguiente:
                  </p>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i class="bi bi-person display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>Referencia Personal y su número de celular</p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i class="bi bi-receipt display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>Factura de luz</p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i class="bi bi-bank display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>Cuenta bancaria a tu nombre</p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i class="bi bi-people display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>Carnet de Identidad de su garante (si corresponde)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <div className="p-4 anchocuadro">
                <img
                  src={require("./folks-seated-man-with-tablet.png")}
                  className=""
                  alt="seated-man-with-tablet"
                />
              </div>
            </div>
          </div>
          <div className="alto">
            <div className="d-flex flex-md-row-reverse row text-center mt-4">
              <div className="col-md-3 p-md-2 p-2 px-4">
                <Link to="/step26">
                  <button
                    type="button"
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  >
                    Continuar
                  </button>
                </Link>
              </div>
              <div className="col-md-3 p-md-2 p-2 px-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwentyfive;
