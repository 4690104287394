import React from "react";
import IconWithPopover from "./iconWithPopOver";
const ResultComponent = ({ value1, value2, dropdownValue }) => {
  const result =
    (0.03 * Math.pow(1.03, value2) * value1) / (Math.pow(1.03, value2) - 1);
  const interes = (value1 * 0.03).toFixed(2);
  const capital = (result - interes).toFixed(2);
  const seguro = (value1 * 0.00049).toFixed(2);
  const seguroDesempleo = (value1 * 0.00015).toFixed(2);
  const total =
    parseFloat(interes) +
    parseFloat(capital) +
    parseFloat(seguro) +
    parseFloat(seguroDesempleo);

  return (
    <div>
      {/*  <h2>Result:</h2>
      <p>{calculateResult()}</p> */}
      <div className="row">
        <div className="col-7">
          <p>Capital</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. {capital}</p>
        </div>
        <div className="col-1">
          <IconWithPopover textPopOver="El capital es la cantidad de dinero que solicitas a través de un préstamo. Es el monto principal que debes devolver, sin incluir los intereses u otros costos adicionales." />
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <p>Interés (3% mensual)</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. {interes}</p>
        </div>
        <div className="col-1">
          <IconWithPopover textPopOver="Parte del costo adicional que se cobra por el uso del dinero prestado." />
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <p>Seguro desgravamen</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. {seguro}</p>
        </div>
        <div className="col-1">
          <IconWithPopover
            textPopOver="Este tipo de seguro brinda seguridad financiera a los prestatarios y a sus seres queridos, ya que garantiza que la deuda no recaiga sobre ellos en caso de que ocurra un evento inesperado como el fallecimiento del prestatario.
"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <p>Seguro desempleo</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. {seguroDesempleo}</p>
        </div>
        <div className="col-1">
          <IconWithPopover textPopOver="Si el prestatario pierde su empleo, el seguro de desempleo entra en acción y comienza a cubrir los pagos del préstamo durante el período establecido en la póliza. Esto brinda un alivio temporal al prestatario y evita que caiga en incumplimiento de pago." />
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <p>Gastos administrativos</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. 0.00</p>
        </div>
        <div className="col-1">
          <IconWithPopover
            textPopOver="Los gastos administrativos pueden incluir una variedad de conceptos, como la evaluación crediticia, la revisión de la solicitud, la elaboración de documentación legal, la administración de cuentas, entre otros.
"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <p>Impuestos IVA</p>
        </div>
        <div className="col-4 text-end">
          <p>Bs. 0.00</p>
        </div>
        <div className="col-1">
          <IconWithPopover
            textPopOver="Es el cobro por la emisión de la nota fiscal del interés cobrado.
"
          />
        </div>
      </div>
      <hr className="mt-0" />
      <p className="text-end">
        <strong>Total &gt; Bs. {total.toFixed(2)}</strong>
      </p>
    </div>
  );
};

export default ResultComponent;
