import React, { useEffect } from "react";

const ValidationCode = () => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.href = "https://kivocash.com/";
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <div className="text-info text-center pt-md-5">
      <h1>Verifica tu correo electrónico</h1>
      <h4>Tienes un mensaje de KIVO</h4>
      <i class="bi bi-envelope-check display-1"></i>
      <h6>Revisa la carpeta de <b>SPAM</b> en tu correo por si acaso! Tal vez el mensaje esté escondido allí.</h6>
    </div>
  );
};

export default ValidationCode;
