export function UpdateLocalProfile(
  nombres,
  primerApellido,
  segundoApellido,
  numeroDocumento,
  fechaNacimiento
) {
  localStorage.setItem("nombres", nombres);
  localStorage.setItem("primerApellido", primerApellido);
  localStorage.setItem("segundoApellido", segundoApellido);
  localStorage.setItem("numeroDocumento", numeroDocumento);
  localStorage.setItem("fechaNacimiento", fechaNacimiento);

  return;
}
