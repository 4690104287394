import React, { useState } from 'react'
import Header from "../../Components/Header.jsx";
import imagenFeliz from "../encuesta/emoji feliz.png";
import imagenMedio from "../encuesta/emoji mas o menos.png";
import imagenEnojado from "../encuesta/emoji enojado.png";
import './Encuesta.css'
import { useNavigate } from 'react-router-dom';

export default function Encuesta2() {
    const navigate = useNavigate();
    const [tiempo_atencion, setAtencion] = useState('');

    
    // Función para manejar cambios en la selección de tiempo de atencion
    const handleAtencionChange = (e) => {
        const value = e.target.value;
        setAtencion(value);
        // Guardar la selección de tiempo de atencion en localStorage
        localStorage.setItem('tiempo_atencion', value);
    };

    const handleGuardar = () => {
        localStorage.setItem('tiempo_atencion', tiempo_atencion);
        console.log('Datos guardados en localStorage:', tiempo_atencion);
        navigate('/encuesta3');
    };

  return (
    <div className='container'>
        <Header textHeader="¿Cómo calificarías el tiempo de atención?"/>
        <div className='anchos shadow rounded-4 mb-3 col-sm-12' style={{textAlign:"center", backgroundColor:"#D1D2D2"}}>
            
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-3' src={imagenFeliz} alt="emoji" style={{textAlign:"center"}}/>
                <div>   
                <input className="form-check-input" type="radio" name="tiempo_atencion" id="inlineRadio1" value="excelente" checked={tiempo_atencion === 'excelente'} onChange={handleAtencionChange}/>
                <label className="form-check-label" htmlFor="inlineRadio1">Excelente</label>
                </div>
                
            </div>
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-4' src={imagenMedio} alt="emoji" style={{textAlign:"center"}}/>
                <div>
                    <input className="form-check-input" type="radio" name="tiempo_atencion" id="inlineRadio2" value="buena" checked={tiempo_atencion === 'buena'} onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="inlineRadio1">Buena</label>
                </div>                
            </div>
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-4' src={imagenEnojado} alt="emoji" style={{textAlign:"center"}}/>
                <div>
                    <input className="form-check-input" type="radio" name="tiempo_atencion" id="inlineRadio3" value="mala" checked={tiempo_atencion === 'mala'} onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="inlineRadio3">Mala</label>
                </div>                
            </div>
        </div>
        <div className='container col-xl-4'>
                <button onClick={handleGuardar}
                    className="btn btn-primary mt-2 w-100"
                    type="submit"
                    style={{color:"white", font:'LemonMilk', fontSize:"20px"}}
                >
                    Siguiente
                </button>
        </div>
        
    </div>
  )
}
