import React, { useState } from "react";
import "./login.css";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import Header from "../../Components/Header.jsx";
import { useNavigate, useLocation } from "react-router-dom";

function RestablecerContrasena() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codigo = queryParams.get("code");
  console.log("codigo:", codigo);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const url = `${process.env.REACT_APP_API_BACKEND}/public/reestablecer-contrasena`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          contrasena: password,
          codigoVerificacion: codigo,
          verificacionContrasena: confirmPassword,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data.datos.mensaje);
        setLoading(false);
        navigate("/signin");
      } else {
        const data = await response.json();
        console.log(await data.mensaje);
        setError({
          message: data.mensaje,
          type: "danger",
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("Verification failed! ", error.response.data.mensaje);
      setError({
        message: "Ocurrió un error inesperado, intente de nuevo",
        type: "danger",
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Header textHeader="Restablecer Contraseña" />
      <ErrorHandling error={error} clearError={clearError} />
      <div className="ancho-img">
        <div className="shadow">
          <img
            src={require("./Logo-KIVO-PNG.png")}
            className="w-100 rounded-top-4"
            alt="logo kivo"
          />
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-2 px-md-4 pb-2">
              <div>
                <div className="pt-3">
                  <h4 className="text-secondary">Restablece tu contraseña</h4>
                  <label>Nueva contraseña</label>
                  <div className="input-group">
                    <input
                      className="form-control rounded-start-4"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Escriba su contraseña"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span
                      className="input-group-text rounded-end-4"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="pt-3">
                  <label>Repite la nueva contraseña</label>
                  <div className="input-group">
                    <input
                      className="form-control rounded-start-4"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Escriba su contraseña"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <span
                      className="input-group-text rounded-end-4"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-primary mt-3 w-100"
                type="submit"
                disabled={loading}
              >
                {loading ? "Ingresando..." : "Ingresar"}
              </button>
              <p className="pt-2 text-center"></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default RestablecerContrasena;
