const handleDownloadPdf = (base64) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const fileName = "file.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export default handleDownloadPdf;
