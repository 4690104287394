export const handleUpdate = async (Step) => {
  const idSolicitud = localStorage.getItem("idSolicitud");
  const token = localStorage.getItem("token");

  if (!token) {
    console.log("No hay token");
    return;
  }

  const url = `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${idSolicitud}/actualizar-paso`;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({ pasoActual: Step }), // Envía Step en el cuerpo de la solicitud
    });

    if (!response.ok) {
      throw new Error(
        "Error en la respuesta del servidor: " + response.statusText
      );
    }

    const data = await response.json();
    console.log("Respuesta del servidor:", data);
  } catch (error) {
    console.error("Hubo un error en la petición:", error);
  }
};
