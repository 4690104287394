import React, { useRef, useEffect, useState } from "react";
import Header from "../../Components/Header.jsx";
import { Link } from "react-router-dom";

function StepTwentysix() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageCaptured, setImageCaptured] = useState(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error("Error accessing the camera", err);
        });
    } else {
      alert("Tu navegador no soporta la API getUserMedia.");
    }

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, 640, 480); // Video es 640x480
      const imgData = canvasRef.current.toDataURL();
      setImageCaptured(imgData);
    }
  };

  return (
    <div>
      <Header textHeader="Datos de Garante" />

      <div className="container">
        <p className="text-center">
          Por favor toma una foto del Anverso del Carnet de Identidad de tu
          Garante
        </p>
        <center>
          {!imageCaptured && (
            <video
              ref={videoRef}
              className="w-50 mt-4 rounded-3"
              autoPlay
              playsInline
            ></video>
          )}
          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
            width={640}
            height={480}
          ></canvas>
          {imageCaptured && (
            <img
              src={imageCaptured}
              alt="Captured"
              className="w-50 my-4 rounded-3"
            />
          )}
        </center>

        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <center>
              <div className="w-50 p-md-2 p-2">
                {imageCaptured ? (
                  <a href="/step26">
                    <button className="w-100 btn btn-secondary btn-lg rounded-pill shadow text-white">
                      Tomar otra foto
                    </button>
                  </a>
                ) : (
                  <button
                    onClick={captureImage}
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  >
                    Tomar Foto
                  </button>
                )}
              </div>
            </center>
          </div>
        </div>
        <div className="container mt-4">
          <div className="d-flex flex-md-row-reverse row text-center">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="/step27">
                <button className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white">
                  Siguiente
                </button>
              </Link>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="/step25">
                <button
                  type="submit"
                  className="w-100 btn btn-light btn-lg rounded-pill shadow"
                >
                  Atrás
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwentysix;
