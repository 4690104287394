import React from "react";

function Politicas() {
  return (
    <section class="relative pt-[40px] lg:pt-[80px] pb-[100px]">
      <div className="container">
        <div>
          <div
            style={{ textAlign: "justify", font: "LemonMilk", margin: "20px" }}
          >
            <h1 class="{{styles.h2}} mb-16" style={{ textAlign: 'center' }}>
              GENCORP S.R.L.
              <br />
              Política de Privacidad
            </h1>
            <p class="{{styles.paragraphRegular}}">
              La presente Política de Privacidad establece los términos en que
              GENCORP SRL (Gencorp) usa y protege la información que es
              proporcionada por sus usuarios al momento de utilizar su sitio web
              y plataforma. Gencorp está comprometida con la seguridad de los
              datos de sus usuarios. Cuando le pedimos llenar los campos de
              información personal con la cual usted pueda ser identificado, lo
              hacemos asegurando que sólo se empleará de acuerdo con los
              términos de este documento. Sin embargo, esta Política de
              Privacidad puede cambiar con el tiempo o ser actualizada por lo
              que le recomendamos y enfatizamos revisar continuamente esta
              página para asegurarse que está de acuerdo con dichos cambios.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">
              INFORMACIÓN QUE ES RECOGIDA
            </h3>
            <p class="{{styles.paragraphRegular}}">
              Nuestro sitio web podrá recoger información personal, por ejemplo:
              Nombre, información de contacto como su dirección de correo
              electrónica e información demográfica. Así mismo cuando sea
              necesario podrá ser requerida información específica para procesar
              algún requerimiento para envió o entrega de información.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">
              USO DE LA INFORMACIÓN RECOGIDA
            </h3>
            <p class="{{styles.paragraphRegular}}">
              Nuestro sitio web emplea la información con el fin de proporcionar
              el mejor servicio posible, particularmente para mantener un
              registro de usuarios, de darse de alta en nuestra plataforma en
              caso que aplique, y mejorar nuestros productos y servicios. Es
              posible que sean enviados correos electrónicos periódicamente a
              través de nuestro sitio con ofertas especiales, nuevos productos y
              otra información publicitaria que consideremos relevante para
              usted o que pueda brindarle algún beneficio, estos correos
              electrónicos serán enviados a la dirección que usted proporcione y
              podrán ser cancelados en cualquier momento.
            </p>
            <p class="{{styles.paragraphRegular}}">
              Gencorp está altamente comprometido para cumplir con el compromiso
              de mantener su información segura. Aplicamos nuestro esfuerzo más
              diligente, Tratamos de usar los sistemas más avanzados y los
              actualizamos constantemente para asegurarnos que no exista ningún
              acceso no autorizado.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">COOKIES</h3>
            <p class="{{styles.paragraphRegular}}">
              Nuestro sitio web emplea las cookies para poder identificar las
              páginas que son visitadas y su frecuencia. Una cookie se refiere a
              un fichero que es enviado con la finalidad de solicitar permiso
              para almacenarlo en su ordenador, al aceptar dicho fichero se crea
              y la cookie sirve entonces para tener información respecto al
              tráfico web.
            </p>
            <p class="{{styles.paragraphRegular}}">
              Esta información es empleada únicamente para análisis estadístico
              y después la información se elimina de forma permanente. Usted
              puede eliminar las cookies en cualquier momento desde su
              ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor
              servicio de los sitios web, estás no dan acceso a información de
              su ordenador ni de usted, a menos de que usted así lo quiera y la
              proporcione directamente. Usted puede aceptar o negar el uso de
              cookies, sin embargo, la mayoría de navegadores aceptan cookies
              automáticamente pues sirve para tener un mejor servicio web.
              También usted puede cambiar la configuración de su ordenador para
              declinar las cookies. Si se declinan es posible que no pueda
              utilizar algunos de nuestros servicios.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">
              CONTROL DE SU INFORMACIÓN PERSONAL
            </h3>
            <p class="{{styles.paragraphRegular}}">
              En cualquier momento usted puede restringir la recopilación o el
              uso de la información personal que es proporcionada a nuestro
              sitio web. Cada vez que se le solicite rellenar un formulario,
              como el de alta de usuario, puede marcar o desmarcar la opción de
              recibir información por correo electrónico.
            </p>
            <p class="{{styles.paragraphRegular}}">
              Gencorp no venderá, cederá ni distribuirá la información personal
              que es recopilada sin su consentimiento, salvo que sea requerido
              por un juez con un orden judicial.
            </p>
            <p class="{{styles.paragraphRegular}}">
              Se reserva el derecho de cambiar los términos de la presente
              Política de Privacidad en cualquier momento.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">TUS DERECHOS</h3>
            <p class="{{styles.paragraphRegular}}">
              Gencorp te informa que sobre tus datos personales tienes derecho
              a:
            </p>
            <ul class="listado_politicas">
              <li>Solicitar el acceso a los datos almacenados.</li>
              <li>Solicitar una rectificación o la cancelación.</li>
              <li>Solicitar la limitación de su tratamiento.</li>
              <li>Oponerte al tratamiento.</li>
              <li>Solicitar la portabilidad de tus datos.</li>
            </ul>
            <p class="{{styles.paragraphRegular}}">
              El ejercicio de estos derechos es personal y por tanto debe ser
              ejercido directamente por el interesado, solicitándolo
              directamente a Gencorp lo que significa que cualquier cliente,
              suscriptor o colaborador que haya facilitado sus datos en algún
              momento puede dirigirse a Gencorp y pedir información sobre los
              datos que tiene almacenados y cómo los ha obtenido, solicitar la
              rectificación de los mismos, solicitar la portabilidad de sus
              datos personales, oponerse al tratamiento, limitar su uso o
              solicitar la cancelación de esos datos en los ficheros del sitio
              web.
            </p>
            <p class="{{styles.paragraphRegular}}">
              Para ejercitar tus derechos de acceso, rectificación, cancelación,
              portabilidad y oposición tienes que enviar un correo electrónico a
              info@gencorpbo.com junto con la prueba válida en derecho como una
              fotocopia de tu Cedula de Identidad o equivalente.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">
              PROCEDIMIENTO DE SEGURIDAD, CONTRASEÑAS
            </h3>
            <p class="{{styles.paragraphRegular}}">
              En Gencorp, Implementamos procedimientos de seguridad para
              proteger las cuentas de usuario y contraseñas. Aunque hacemos
              esfuerzos para garantizar la seguridad de la información, ningún
              sistema es infalible. Los usuarios deben asumir el riesgo al
              proporcionar sus datos. Recopilamos información de dispositivos
              para un funcionamiento optimo y prevenir suplantaciones. Nos
              reservamos el derecho de realizar cambios en la Política de
              Privacidad son previo aviso. Se recomienda a los usuarios revisar
              periódicamente estas condiciones.
            </p>
            <h3 class="{{styles.h6}} mt-10 mb-0">
              ACEPTACIÓN DE LA POLÍTICA DE PRIVACIDAD
            </h3>
            <p class="{{styles.paragraphRegular}}">
              El usuario declara haber leído la Política de Privacidad y la
              acepta expresamente si es que marca su aceptación con un clic en
              el botón correspondiente o si continúa usando la Plataforma. En
              caso de no estar de acuerdo con la Política de Privacidad o
              cualquiera de sus modificaciones, el Usuario podrá rechazarlas
              dejando de utilizar las Plataforma.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Politicas;
