import React, { useState, useEffect } from "react";
import axios from "axios";
import "./login.css";
import Header from "../../Components/Header.jsx";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DisclaimerModal from "../../Components/PoliticasDePrivacidad";
import Calendario from "../../Components/Calendario.jsx";
import { format } from "date-fns";

const SignUp = () => {
  //para terminos y condiciones
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTerminos, setShowTerminos] = useState(false);
  const handleClose = () => setShowDisclaimer(false);
  const handleShow = () => setShowDisclaimer(true);
  const handleCloseT = () => setShowTerminos(false);
  const handleShowT = () => setShowTerminos(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // const eighteenYearsAgo = new Date();
  // eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 22);
  // const maxDate = eighteenYearsAgo.toISOString().split("T")[0];

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      setFormData((prevFormData) => ({
        ...prevFormData,
        fechaNacimiento: formattedDate,
      }));
    }
  }, [selectedDate]);

  //defining the form params to kivo server
  const [formData, setFormData] = useState({
    nombres: "",
    primerApellido: "",
    segundoApellido: "",
    celular: "",
    ciudad: "",
    numeroDocumento: "",
    fechaNacimiento: "",
    correoElectronico: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Regular expression to match only letters (and space, hyphens, apostrophes if needed)

    console.log("format date", selectedDate);

    const nameRegex =
      /^[A-Za-záéíóúÁÉÍÓÚñÑüÜ'-]+(?:\s[A-Za-záéíóúÁÉÍÓÚñÑüÜ'-]+)*$/;

    // Check if names and surnames are filled in and valid
    if (!nameRegex.test(formData.nombres.trim())) {
      setAlert({
        visible: true,
        message: "El nombre contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }
    if (!nameRegex.test(formData.primerApellido.trim())) {
      setAlert({
        visible: true,
        message: "El primer apellido contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }
    if (
      formData.segundoApellido &&
      !nameRegex.test(formData.segundoApellido.trim())
    ) {
      setAlert({
        visible: true,
        message: "El segundo apellido contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 3000);
      return;
    }
    const carnetIdentidad = /^\d{6,}$/;
    if (!carnetIdentidad.test(formData.numeroDocumento)) {
      setAlert({ visible: true, message: "Verifique su numero de Carnet." });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }
    const phoneRegex = /^(?=[6-9]\d{7}$)\d{8}$/;
    if (!phoneRegex.test(formData.celular)) {
      setAlert({ visible: true, message: "Verifique el número de Teléfono." });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }

    // Password validation regex
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setAlert({
        visible: true,
        message:
          "La contraseña debe tener al menos 8 caracteres, incluir una mayúscula y un número.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setAlert({ visible: true, message: "Las contraseñas no coinciden." });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }

    // Proceed with form submission if all checks pass
    setLoading(true);
    console.log(formData);
    //only because you have to send it twice please delete after

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/public/register`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setLoading(false);
      console.log(response);

      navigate("/sentemail");
    } catch (error) {
      setLoading(false);
      console.error("Error de respuesta:", error.response.data.mensaje);
      setAlert({
        visible: true,
        message: JSON.stringify(error.response.data.mensaje),
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 3000);
    }
  };

  return (
    <div className="mx-4">
      <Header textHeader="¡Regístrate!" />

      {loading && <div className="alert alert-success">Enviando datos...</div>}
      {alert.visible && (
        <div className="alert alert-danger">{alert.message}</div>
      )}
      <div className="anchos bg-white shadow rounded-4">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Nombre (s)<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="nombres"
                  className="form-control"
                  value={formData.nombres}
                  onChange={handleChange}
                  placeholder="Nombre (s)"
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Primer Apellido<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="primerApellido"
                  className="form-control"
                  value={formData.primerApellido}
                  onChange={handleChange}
                  placeholder="Primer Apellido"
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">Segundo Apellido</label>
                <input
                  type="text"
                  name="segundoApellido"
                  className="form-control"
                  value={formData.segundoApellido}
                  onChange={handleChange}
                  placeholder="Segundo Apellido"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 py-md-4">
                <label className="text-info">
                  Carnet de Identidad<span className="text-danger"> *</span>
                </label>
                <input
                  type="number"
                  name="numeroDocumento"
                  className="form-control"
                  value={formData.numeroDocumento}
                  onChange={handleChange}
                  placeholder="Carnet de Identidad"
                  required
                />
              </div>
              <div className="col-md-3 py-md-4">
                <label className="text-info">
                  Correo Electrónico<span className="text-danger"> *</span>
                </label>
                <input
                  type="email"
                  name="correoElectronico"
                  className="form-control"
                  value={formData.correoElectronico}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-md-3 py-md-4">
                <label className="text-info">
                  Número de Teléfono<span className="text-danger"> *</span>
                </label>
                <input
                  type="number"
                  name="celular"
                  className="form-control"
                  value={formData.celular}
                  onChange={handleChange}
                  placeholder="Teléfono"
                  required
                />
              </div>
              <div className="col-md-3 py-md-4">
                <label className="text-info">
                  Ciudad de residencia<span className="text-danger"> *</span>
                </label>
                <select
                  name="ciudad"
                  value={formData.ciudad}
                  className="form-control"
                  onChange={handleChange}
                  placeholder="Ciudad"
                  required
                >
                  <option value="">Selecciona una ciudad</option>
                  <option value="La Paz">La Paz</option>
                  <option value="Oruro">Oruro</option>
                  <option value="Potosi">Potosí</option>
                  <option value="Cochabamba">Cochabamba</option>
                  <option value="Sucre">Sucre</option>
                  <option value="Tarija">Tarija</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Beni">Beni</option>
                  <option value="Pando">Pando</option>
                </select>
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Fecha de Nacimiento<span className="text-danger"> *</span>
                </label>

                <Calendario
                  value={selectedDate}
                  onChange={setSelectedDate}
                  minYearSet="18"
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Contraseña<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    className="form-control"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                  <span
                    className="input-group-text rounded-end"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Confirmar Contraseña<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    className="form-control"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="Confirmar Contraseña"
                    required
                  />
                  <span
                    className="input-group-text rounded-end"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span className="px-2">
                  Acepto las &nbsp;
                  <Link className="text-secondary" onClick={handleShow}>
                    Políticas de Privacidad
                  </Link>
                  &nbsp;y los&nbsp;
                  <Link className="text-secondary" onClick={handleShowT}>
                    Términos y Condiciones
                  </Link>
                </span>

                <DisclaimerModal
                  show={showDisclaimer}
                  handleClose={handleClose}
                  title="Políticas de Privacidad"
                  url="https://onboarding-dev.kivocash.com/politica-de-privacidad"
                />
                <DisclaimerModal
                  show={showTerminos}
                  handleClose={handleCloseT}
                  title="Términos y Condiciones"
                  url="https://onboarding-dev.kivocash.com/terminos-y-condiciones"
                />
              </div>
            </div>
            <button
              disabled={!isChecked}
              type="submit"
              className="btn btn-primary mt-3"
            >
              Verificar por correo electrónico
            </button>
            <p className="pt-4">
              ¿Ya tienes una cuenta?, <Link to="/signin">ingresa aquí</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
