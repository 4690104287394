import "../App.css";
function Logo() {
  return (
    <div className="logoclass">
      <img
        src={require("./Logo KIVO PNG.png")}
        className="img-fluid p-4"
        width="150"
        alt="Logo"
      />
    </div>
  );
}
export default Logo;
