import React, { useEffect, useState } from "react";
import "./step6.css"; // Asegúrate de que este es el camino correcto a tu archivo CSS

const SliderComponent = ({ min, max, step, label, value, onChange }) => {
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    setSliderValue(value);
    // Agregar una clase para la animación inicial
    const thumb = document.querySelector(".custom-slider-thumb");
    if (thumb) {
      thumb.classList.add("animate-thumb");
      setTimeout(() => thumb.classList.remove("animate-thumb"), 1000);
    }
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSliderValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div>
      <h5>
        {label} {sliderValue}
      </h5>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleChange}
        className="custom-slider w-100"
        style={{
          background: `linear-gradient(to right, #3497F1 0%, #2C3F81 ${
            ((sliderValue - min) / (max - min)) * 100
          }%, #99ddff ${
            ((sliderValue - min) / (max - min)) * 100
          }%, #99ddff 100%)`,
        }}
      />
    </div>
  );
};

export default SliderComponent;
