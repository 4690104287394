import { useNavigate, useLoaderData } from "react-router-dom";
import React, { useState } from "react";
import SliderComponent from "./SliderComponent";
import ResultComponent from "./ResultComponent";
import Header from "../../Components/Header.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import axios from "axios";
import "./step6.css";

const StepSix = () => {
  const token = useLoaderData();
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const idSolicitud = localStorage.getItem("idSolicitud");
  console.log("token6:", token);
  const Step = "/step7";
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  const initialValue1 = 5000;
  const finalValue1 = 21000;
  const stepValue1 = 1000;
  const initialValue2 = 3;
  const finalValue2 = 18;
  const stepValue2 = 1;

  const [value1, setValue1] = useState(initialValue1);
  const [value2, setValue2] = useState(initialValue2);
  const [dropdownValue, setDropdownValue] = useState(1);
  const [showWarning, setShowWarning] = useState(false);

  const navigate = useNavigate();

  const handleSliderChange1 = (newValue) => {
    setValue1(newValue);
  };

  const handleSliderChange2 = (newValue) => {
    setValue2(newValue);
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(Number(event.target.value));
  };

  const handleSubmitF = async (event) => {
    //event.preventDefault();
    if (value1 > 15000) {
      setShowWarning(true);
      return;
    }
  };

  const handleSubmit = async (event) => {
    let data = {
      monto: value1,
      plazo: value2,
      dia: dropdownValue,
    };

    console.log("data che:", data);

    localStorage.setItem("simulador", JSON.stringify(data));

    let config = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${idSolicitud}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const { data } = await axios(config);
      console.log("Response data loaded:", data.datos);
      manejarClick();
    } catch (error) {
      console.error("Error uploading image:", error.response.data.mensaje);
      setError({
        message: error.response.data.mensaje,
        type: "danger",
      });
    }
  };

  return (
    <div>
      <div>
        <Header textHeader={"Simulador de Préstamos"} />
        <h4 className="text-center">
          Ingresa los detalles y te proporcionaremos la estimación de tu pago
          mensual. <br />
          ¡Es fácil y rápido!
        </h4>
      </div>
      <ErrorHandling error={error} clearError={clearError} />
      <div className="container pt-4">
        <div className="row ">
          <div className="col-md-6 mx-auto pb-4 pb-md-0 d-flex justify-content-center">
            <div>
              <div className="p-4 p-md-5 anchocuadro6 rounded-4 bg-white shadow d-flex justify-content-center flex-column">
                <div>
                  <p className="text-info">
                    ¿Cuánto dinero necesitas?
                    <br />
                    <span className="small text-black">Desliza el círculo</span>
                  </p>

                  <SliderComponent
                    min={initialValue1}
                    max={finalValue1}
                    step={stepValue1}
                    value={value1}
                    onChange={handleSliderChange1}
                    label="Bs. "
                  />
                  <p className="d-flex justify-content-between">
                    <span>5000</span>
                    <span>21000</span>
                  </p>
                  <p className="text-info mt-md-2">
                    ¿A cuántos meses?
                    <br />
                    <span className="small text-black">Desliza el círculo</span>
                  </p>
                  <SliderComponent
                    min={initialValue2}
                    max={finalValue2}
                    step={stepValue2}
                    value={value2}
                    onChange={handleSliderChange2}
                    label="Meses:"
                  />
                  <p className="d-flex justify-content-between">
                    <span>3</span>
                    <span>18</span>
                  </p>
                  <div>
                    <p className="text-info">
                      Selecciona una fecha del 1 al 15 para realizar tus pagos
                      cada mes
                    </p>
                    <select
                      value={dropdownValue}
                      onChange={handleDropdownChange}
                    >
                      {Array.from({ length: 15 }, (_, i) => i + 1).map(
                        (value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {showWarning && (
              <div className="overlay">
                <div className="warning rounded-3">
                  <p>
                    Para montos superiores a Bs. 15.000 debes contar con un
                    garante.
                  </p>
                  <p>Te pediremos información sobre tu garante más adelante</p>

                  <button
                    onClick={() => {
                      setShowWarning(false);
                      handleSubmit();
                    }}
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6 d-flex justify-content-center  mx-auto">
            <div className="p-4 p-md-4 anchocuadro6 rounded-4 bg-white shadow d-flex justify-content-center flex-column">
              <h5 className="text-center text-info">
                Estos son los valores a pagar
              </h5>
              <p className="text-center">Cuota mensual</p>
              <div className="d-flex justify-content-between">
                <span>Monto Solicitado Bs. {value1}</span>
                <span>Plazo {value2} meses</span>
              </div>
              <hr className="mt-0" />
              <ResultComponent
                value1={value1}
                value2={value2}
                dropdownValue={dropdownValue}
              />
              <p className="small">
                *La cuota mensual es referencial y variará en función a la
                aprobación de su préstamo.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            {value1 > 15000 ? (
              <button
                type="button"
                onClick={handleSubmitF}
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
              >
                ¡SOLICITA YA!
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSubmit}
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
              >
                ¡SOLICITA YA!
              </button>
            )}
          </div>
        </div> <br /> <br /> <br />
      </div>
    </div>
  );
};

export default StepSix;
