import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Items from "./Items";
import Header from "../../Components/Header";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

function StepTwenty() {
  const [opcionDeudas, setOpcionDeudas] = useState("Escoge una opción");
  const [hasBgSucess, sethasBgSucess] = useState(false);

  const navigate = useNavigate();
  const Step = "/step21"; //this is the next step
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };

  useEffect(() => {
    // Aquí agregamos el código de monitoreo
    const handleMutation = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          const targetElement = mutation.target;
          if (targetElement.classList.contains("bg-success")) {
            //console.log("La clase bg-success apareció en el elemento:", targetElement);
            sethasBgSucess(true);
            // Puedes realizar otras acciones aquí si es necesario.
          }
        }
      }
    };

    // Configurar el observador de mutaciones
    const observer = new MutationObserver(handleMutation);

    // Observar cambios en el cuerpo del documento (puedes ajustar esto según la estructura de tu aplicación)
    const bodyElement = document.body;
    observer.observe(bodyElement, { attributes: true, subtree: true });

    // Limpiar el observador cuando el componente se desmonte
    return () => {
      observer.disconnect();
    };


  }, []);

  const [creditoHipotecario, setCreditoHipotecario] = useState([
    { numero: 1, count: 1 },
  ]);
  const [creditoBancario, setCreditoBancario] = useState([
    { numero: 1, count: 1 },
  ]);
  const [creditoVehicular, setCreditoVehicular] = useState([
    { numero: 1, count: 1 },
  ]);
  const [tarjetaCredito, setTarjetaCredito] = useState([
    { numero: 1, count: 1 },
  ]);
  const [creditoConsumo, setCreditoConsumo] = useState([
    { numero: 1, count: 1 },
  ]);
  const [prestamosPersonales, setPrestamosPersonales] = useState([
    { numero: 1, count: 1 },
  ]);
  const [otrosCreditos, setOtrosCreditos] = useState([
    { numero: 1, count: 1 },
  ]);



  const handleAddCreditoHipotecario = (index) => {
    if (creditoHipotecario[index].count < 3) {
      const newCreditoHipotecario = [...creditoHipotecario];
      newCreditoHipotecario[index].count++;
      setCreditoHipotecario(newCreditoHipotecario);
    }
  };

  const handleRemoveCreditoHipotecario = (index) => {
    if (creditoHipotecario[index].count > 1) {
      const newCreditoHipotecario = [...creditoHipotecario];
      newCreditoHipotecario[index].count--;
      setCreditoHipotecario(newCreditoHipotecario);
    }
  };

  const handleAddCreditoBancario = (index) => {
    if (creditoBancario[index].count < 3) {
      const newCreditoBancario = [...creditoBancario];
      newCreditoBancario[index].count++;
      setCreditoBancario(newCreditoBancario);
    }
  };

  const handleRemoveCreditoBancario = (index) => {
    if (creditoBancario[index].count > 1) {
      const newCreditoBancario = [...creditoBancario];
      newCreditoBancario[index].count--;
      setCreditoBancario(newCreditoBancario);
    }
  };

  const handleAddCreditoVehicular = (index) => {
    if (creditoVehicular[index].count < 3) {
      const newCreditoVehicular = [...creditoVehicular];
      newCreditoVehicular[index].count++;
      setCreditoVehicular(newCreditoVehicular);
    }
  };

  const handleRemoveCreditoVehicular = (index) => {
    if (creditoVehicular[index].count > 1) {
      const newCreditoVehicular = [...creditoVehicular];
      newCreditoVehicular[index].count--;
      setCreditoVehicular(newCreditoVehicular);
    }
  };

  const handleAddTarjetaCredito = (index) => {
    if (tarjetaCredito[index].count < 3) {
      const newTarjetaCredito = [...tarjetaCredito];
      newTarjetaCredito[index].count++;
      setTarjetaCredito(newTarjetaCredito);
    }
  };

  const handleRemoveTarjetaCredito = (index) => {
    if (tarjetaCredito[index].count > 1) {
      const newTarjetaCredito = [...tarjetaCredito];
      newTarjetaCredito[index].count--;
      setTarjetaCredito(newTarjetaCredito);
    }
  };

  const handleAddCreditoConsumo = (index) => {
    if (creditoConsumo[index].count < 3) {
      const newCreditoConsumo = [...creditoConsumo];
      newCreditoConsumo[index].count++;
      setCreditoConsumo(newCreditoConsumo);
    }
  };

  const handleRemoveCreditoConsumo = (index) => {
    if (creditoConsumo[index].count > 1) {
      const newCreditoConsumo = [...creditoConsumo];
      newCreditoConsumo[index].count--;
      setCreditoConsumo(newCreditoConsumo);
    }
  };

  const handleAddPrestamosPersonales = (index) => {
    if (prestamosPersonales[index].count < 3) {
      const newPrestamosPersonales = [...prestamosPersonales];
      newPrestamosPersonales[index].count++;
      setPrestamosPersonales(newPrestamosPersonales);
    }
  };

  const handleRemovePrestamosPersonales = (index) => {
    if (prestamosPersonales[index].count > 1) {
      const newPrestamosPersonales = [...prestamosPersonales];
      newPrestamosPersonales[index].count--;
      setPrestamosPersonales(newPrestamosPersonales);
    }
  };

  const handleAddOtrosCreditos = (index) => {
    if (otrosCreditos[index].count < 3) {
      const newOtrosCreditos = [...otrosCreditos];
      newOtrosCreditos[index].count++;
      setOtrosCreditos(newOtrosCreditos);
    }
  };

  const handleRemoveOtrosCreditos = (index) => {
    if (otrosCreditos[index].count > 1) {
      const newOtrosCreditos = [...otrosCreditos];
      newOtrosCreditos[index].count--;
      setOtrosCreditos(newOtrosCreditos);
    }
  };
  return (
    <div>
      <Header textHeader="Datos de deudas" />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Form.Group>
              <h5>
                ¿Tienes otros préstamos vigentes?{" "}
                <span className="text-danger">*</span>
              </h5>
              <Form.Select
                className="form-control rounded-pill mt-4"
                value={opcionDeudas}
                onChange={(e) => setOpcionDeudas(e.target.value)}
              >
                <option>Escoge una opción</option>
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </Form.Group>
          </div>
          {opcionDeudas === "Si" && (
            <div>
              <p className="mt-md-2">Para avanzar, necesitamos que ingreses el monto mensual de las cuotas de tus préstamos actuales y subas los Planes de Pago que se aplican. </p>
              {/* Crédito Hipotecario */}
              {creditoHipotecario.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Crédito Hipotecario</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddCreditoHipotecario(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveCreditoHipotecario(index)}
                        >
                          <i class="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`credito-hipotecario`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Crédito Hipotecario */}
              {/* Crédito Bancario */}
              {creditoBancario.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Crédito Bancario</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddCreditoBancario(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveCreditoBancario(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`credito-bancario`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Crédito Bancario */}

              {/* Crédito Vehicular */}
              {creditoVehicular.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Crédito Vehicular</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddCreditoVehicular(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveCreditoVehicular(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`credito-vehicular`}
                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Crédito Vehicular */}

              {/* Tarjeta Crédito */}
              {tarjetaCredito.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Tarjeta Crédito</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddTarjetaCredito(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveTarjetaCredito(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`tarjeta-credito`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Tarjeta Crédito */}

              {/* Crédito Consumo*/}
              {creditoConsumo.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Credito de Consumo</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddCreditoConsumo(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveCreditoConsumo(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`credito-consumo`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Crédito Consumo */}

              {/* Prestamos Personales*/}
              {prestamosPersonales.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Préstamos Personales</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddPrestamosPersonales(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemovePrestamosPersonales(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`prestamo-personal`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Prestamos Personales */}

              {/* Otros Créditos*/}
              {otrosCreditos.map((item, index) => (
                <div key={index}>
                  <div className="row mt-md-4">
                    <div className="col-md-5 row">
                      <div className="col-md-8">
                        <h5>Otros Créditos</h5>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddOtrosCreditos(index)}
                          disabled={item.count >= 3}
                        >
                          <i className="bi bi-plus-lg fs-5"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveOtrosCreditos(index)}
                        >
                          <i className="bi bi-dash fs-5"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {[...Array(item.count)].map((_, subIndex) => (
                        <Items
                          key={subIndex}
                          numero={subIndex + 1}
                          URL={`otros-creditos`}

                          index={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* Fin Otros Creditos */}

            </div>
          )}
        </div>
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            {(opcionDeudas === "No" || hasBgSucess) ? (
              <button
                type="button"
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                onClick={manejarClick}
                disabled={false}
              >
                Siguiente
              </button>
            ) : (
              <button
                type="button"
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                onClick={manejarClick}
                disabled={true}
              >
                Siguiente
              </button>
            )}
          </div>
          <div className="col-md-3 p-md-2 p-2 px-4">

          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwenty;
