import { redirect } from "react-router-dom";

export function action() {
  localStorage.removeItem("token");
  localStorage.removeItem("expiration");
  localStorage.removeItem("nombres");
  localStorage.removeItem("primerApellido");
  localStorage.removeItem("segundoApellido");
  localStorage.removeItem("id");
  localStorage.removeItem("simulador");
  localStorage.removeItem("idSolicitud");
  localStorage.removeItem("numeroDocumento");
  localStorage.removeItem("actualStep");
  localStorage.removeItem("fechaNacimiento");

  return redirect("/");
}
