import React from "react";
import { Form } from "react-router-dom";
import Header from "../../Components/Header.jsx";

function StepNineteen() {
  return (
    <div>
      <Header
        textHeader={
          <React.Fragment>
            Lamentablemente no podemos
            <br />
            otorgarle su préstamo
          </React.Fragment>
        }
      />
      <div className="container">
        <div className="bg-white rounded m-5 px-5 pt-4 pb-5 w-75 mx-auto text-center shadow">
          <p>
            Entendemos que tu situación financiera actual puede no cumplir
            <br />
            con nuestros requisitos, pero no te preocupes, valoramos tu
            <br /> interés y esperamos poder ayudarte en el futuro.
            <br /> Gracias por intentarlo, ¡te esperamos de nuevo!
          </p>
        </div>
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            <Form action="/logout" method="post">
              <button
                type="submit"
                className="w-100 btn btn-danger btn-lg rounded-pill shadow"
              >
                Salir
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepNineteen;
