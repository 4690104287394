import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header.jsx";

function StepTwentyfour() {
  return (
    <div>
      <Header
        textHeader={
          <React.Fragment>
            Lamentablemente no podemos
            <br />
            otorgarte el préstamo
          </React.Fragment>
        }
      />
      <div className="container">
        <div className="bg-white rounded m-5 p-5 w-75 custom-effect mx-auto text-center">
          <p>
            Queremos expresar nuestro sincero agradecimiento por haber pensado
            en Kivo para tus necesidades financieras. Tu confianza y elección
            significan mucho para nosotros.
          </p>
          <p>Gracias por intentarlo.</p>
        </div>
        <div className="alto">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="#">
                <button
                  type="button"
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                >
                  Salir
                </button>
              </Link>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwentyfour;
