import React from "react";
import Header from "../../Components/Header.jsx";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import { useNavigate } from "react-router-dom";

function StepTwelve() {
  const Step = "/step13"; //this is the next step
  const navigate = useNavigate();
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  return (
    <div>
      <Header textHeader="Tómate una selfie" />
      <div
        className="container d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <div className="bg-white rounded px-5 py-2 d-inline-block">
          <center>
            <h5 className="mt-2">IMPORTANTE!</h5>
          </center>
          <ul>
            <li>No uses gorra</li>
            <li>No uses lentes o gafas</li>
            <li>No uses barbijo</li>
          </ul>
        </div>
        <img
          src={require("./daily-man-takes-a-selfie.png")}
          alt="Persona con Celular"
          className="w-25 mt-2"
        />

        <div className="w-100 mt-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <center>
                <div className="p-md-2 p-2 px-4">
                  <button
                    onClick={manejarClick}
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  >
                    Activar la Cámara
                  </button>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepTwelve;
