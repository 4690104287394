import React, { useState } from "react";
import Header from "../../Components/Header.jsx";
import { useNavigate, useLoaderData } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import axios from "axios";

function StepSixteen() {
  const Step = "/step20"; //this is the next step
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const token = useLoaderData();
  // console.log("token16:", token);
  const navigate = useNavigate();
  const [fileSelected, setFileSelected] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [selectedFileNames, setSelectedFileNames] = useState(["", "", "", ""]);
  const [documentTitles] = useState([
    "Extracto aportes a la Gestora",
    "Extracto bancario",
    "Boletas de pago",
    "Contrato de Trabajo",
  ]);
  const isNecessaryDocument = (index) => {
    const condicionComun =
      (!!fileSelected[0] || !!fileSelected[1]) &&
      (!!fileSelected[2] || !!fileSelected[3]);
    const condicionAdicional =
      (!!fileSelected[0] && !!fileSelected[1]) || !!fileSelected[index];

    return condicionComun || condicionAdicional ? null : (
      <span className="text-danger">Este documento es necesario</span>
    );
  };
  const var1 = isNecessaryDocument(0);
  const var2 = isNecessaryDocument(1);
  const var3 = isNecessaryDocument(2);
  const var4 = isNecessaryDocument(3);
  const documentSubtitles = [
    <>{var1}</>,
    <>(3 últimos meses) {var2}</>,
    <>(3 últimos meses) {var3} </>,
    <>{var4} </>,
  ];

  const [selectedFiles, setSelectedFiles] = useState([null, null, null, null]);

  const handleFileChange = async (e, index) => {
    const datosArchivos = [
      {
        url: "/creditos/archivos/extracto-gestora",
        tipo: "EXTRACTO GESTORA",
      },
      {
        url: "/creditos/archivos/extracto-bancario",
        tipo: "EXTRACTO BANCARIO",
      },
      {
        url: "/creditos/archivos/boletas-pago",
        tipo: "BOLETAS PAGO",
      },
      {
        url: "/creditos/archivos/contrato-trabajo",
        tipo: "CONTRATO TRABAJO",
      },
    ];

    const formData = new FormData();

    const datosArchivo = datosArchivos[index];

    formData.append("archivo", e.target.files[0]);
    formData.append("idSolicitud", localStorage.getItem("idSolicitud"));
    formData.append("tipo", datosArchivo.tipo);

    const init = {
      method: "POST",
      url: `${process.env.REACT_APP_API_BACKEND}${datosArchivo.url}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      maxBodyLength: Infinity,
      data: formData,
    };

    try {
      const { data } = await axios(init);
      console.log("datos", data);
      const file = e.target.files[0];
      const updatedFileSelected = [...fileSelected];
      updatedFileSelected[index] = true;
      setFileSelected(updatedFileSelected);

      const updatedSelectedFiles = [...selectedFiles];
      updatedSelectedFiles[index] = file; // Almacenar el objeto del archivo
      setSelectedFiles(updatedSelectedFiles);

      const updatedFileNames = [...selectedFileNames];
      updatedFileNames[index] = file.name;
      setSelectedFileNames(updatedFileNames);
    } catch (error) {
      console.error("Error uploading image:", error.response.data.mensaje);
      const updatedFileSelected = [...fileSelected];
      updatedFileSelected[index] = false;
      setFileSelected(updatedFileSelected);

      const updatedSelectedFiles = [...selectedFiles];
      updatedSelectedFiles[index] = null; // Restablecer el objeto del archivo
      setSelectedFiles(updatedSelectedFiles);

      const updatedFileNames = [...selectedFileNames];
      updatedFileNames[index] = "";
      setSelectedFileNames(updatedFileNames);
      setError({
        message:
          error.response.data.finalizado === false &&
          error.response.data.mensaje ===
            "El documento no es legible o no tiene la informacion necesaria."
            ? error.response.data.mensaje
            : "Debes elegir un Archivo",
        type: "danger",
      });
    }
  };

  // Verificar si todos los documentos están seleccionados
  const formValid = () => {
    return (
      (!!fileSelected[0] && !!fileSelected[1]) ||
      ((!!fileSelected[0] || !!fileSelected[1]) &&
        (!!fileSelected[2] || !!fileSelected[3]))
    );
  };
  console.log("resultado", formValid());

  const handleSubmit = (event) => {
    event.preventDefault();
    manejarClick();
  };

  return (
    <div>
      <Header textHeader="Información Financiera" />
      <ErrorHandling error={error} clearError={clearError} />
      <div className="container">
        <p>
          Sube al menos dos documentos que respalden tus ingresos. Uno de ellos
          debe ser el extracto de aportes a la Gestora o tu extracto bancario.
          Añade algún otro documento que consideres relevante para verificar tus
          ingresos.
        </p>
        <h5>
          ¿Qué documentos de respaldo tienes?{" "}
          <span className="text-danger">
            <strong>* </strong>
          </span>
        </h5>{" "}
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-10 mx-auto">
              {documentTitles.map((title, index) => (
                <div className="row mb-4" key={index}>
                  <div className="col-md-6">
                    <div className="p-3">
                      <input
                        type="checkbox"
                        id={`respaldoCheckbox${index}`}
                        style={{ display: "none" }}
                      />
                      <label htmlFor={`respaldoCheckbox${index}`}>
                        <i
                          className={`bi bi-check-circle-fill ${
                            fileSelected[index] ? "text-success" : "text-dark"
                          }`}
                        ></i>{" "}
                        <strong>{title}</strong>
                        <br />
                        <span className="text-muted text-small">
                          {documentSubtitles[index]}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className={`w-100 ${
                        fileSelected[index]
                          ? "bg-success text-white"
                          : "bg-dark text-black"
                      } rounded p-3 text-center border-primary border-3`}
                      htmlFor={`pdfUpload${index}`}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-upload"></i>{" "}
                      {fileSelected[index]
                        ? selectedFileNames[index]
                        : "Carga de documentos"}
                    </label>
                    <input
                      type="file"
                      className="form-control-file"
                      id={`pdfUpload${index}`}
                      accept=".pdf, .jpeg, .jpg, .png"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="container">
            <div className="d-flex flex-md-row-reverse row text-center mt-4">
              <div className="col-md-3 p-md-2 p-2 px-4">
                {formValid() ? (
                  <button
                    type="submit"
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  >
                    Siguiente
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                    disabled={true}
                  >
                    Siguiente
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StepSixteen;
