import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./pages/Root";
import ErrorFallback from "./pages/Login/ErrorHandling";
import LogIn from "./pages/Login/Login";
import SignUp from "./pages/Login/Signup";
import Profile from "./pages/Profile/Profile";
//import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Step9 from "./pages/step9/Step9";
import Step10 from "./pages/step10/Step10";
import Step11 from "./pages/step11/Step11";
import Step12 from "./pages/step12/Step12";
import Step13 from "./pages/step13/Step13";

import Step15 from "./pages/step15/Step15";
import Step16 from "./pages/step16/Step16";

import Step18 from "./pages/step35/Step18";
import Rechazo from "./pages/rechazo/Rechazo";
import Step20 from "./pages/step20/Step20";
import Step21 from "./pages/step21/Step21";

import Step23 from "./pages/step23/Step23";
import Step24 from "./pages/step24/Step24";
import Step25 from "./pages/step25/Step25";
import Step26 from "./pages/step26/Step26";
import Step27 from "./pages/step27/Step27";
import Step28 from "./pages/step28/Step28";
import Step29 from "./pages/step29/Step29";
import Step30 from "./pages/step30/Step30";
import Step31 from "./pages/step31/Step31";
import Step32 from "./pages/step32/Step32";
import Step33 from "./pages/step33/Step33";
import SentEmail from "./pages/Login/SentEmail";
import Contrasena from "./pages/Login/OlvidarContrasena";
import Restablecer from "./pages/Login/RestablecerContrasena";
import Terminos from "./pages/terminos/Terminos";
import { action as logoutAction } from "./pages/Login/Logout";
import { checkAuthLoader, loginLoader, tokenLoader } from "./pages/Login/auth";
import Politicas from "./pages/politicas/Politicas";
import Encuesta from "./pages/encuesta/Encuesta";
import Encuesta2 from "./pages/encuesta/Encuesta2";
import Encuesta3 from "./pages/encuesta/Encuesta3";
import InicioEncuesta from "./pages/encuesta/InicioEncuesta";
import FinEncuesta from "./pages/encuesta/FinEncuesta";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorFallback />,
    id: "root",
    loader: tokenLoader,
    children: [
      { index: true, element: <SignUp />, loader: loginLoader },
      { path: "profile", element: <Profile />, loader: checkAuthLoader },
      { path: "step2", element: <Step2 />, loader: checkAuthLoader },
      { path: "step3", element: <Step3 />, loader: checkAuthLoader },
      { path: "step4", element: <Step4 />, loader: checkAuthLoader },
      { path: "step5", element: <Step5 />, loader: checkAuthLoader },
      { path: "step6", element: <Step6 />, loader: checkAuthLoader },
      { path: "step7", element: <Step7 />, loader: checkAuthLoader },
      { path: "step8", element: <Step8 />, loader: checkAuthLoader },
      { path: "step9", element: <Step9 />, loader: checkAuthLoader },
      { path: "step10", element: <Step10 />, loader: checkAuthLoader },
      { path: "step11", element: <Step11 />, loader: checkAuthLoader },
      { path: "step12", element: <Step12 />, loader: checkAuthLoader },
      { path: "step13", element: <Step13 />, loader: checkAuthLoader },

      { path: "step15", element: <Step15 />, loader: checkAuthLoader },
      { path: "step16", element: <Step16 />, loader: checkAuthLoader },

      { path: "step18", element: <Step18 />, loader: checkAuthLoader },
      { path: "rechazo", element: <Rechazo />, loader: checkAuthLoader },
      { path: "step20", element: <Step20 />, loader: checkAuthLoader },
      { path: "step21", element: <Step21 />, loader: checkAuthLoader },

      { path: "step23", element: <Step23 />, loader: checkAuthLoader },
      { path: "step24", element: <Step24 />, loader: checkAuthLoader },
      { path: "step25", element: <Step25 />, loader: checkAuthLoader },
      { path: "step26", element: <Step26 />, loader: checkAuthLoader },
      { path: "step27", element: <Step27 />, loader: checkAuthLoader },
      { path: "step28", element: <Step28 />, loader: checkAuthLoader },
      { path: "step29", element: <Step29 />, loader: checkAuthLoader },
      { path: "step30", element: <Step30 />, loader: checkAuthLoader },
      { path: "step31", element: <Step31 />, loader: checkAuthLoader },
      { path: "step32", element: <Step32 />, loader: checkAuthLoader },
      { path: "step33", element: <Step33 />, loader: checkAuthLoader },
      { path: "signin", element: <LogIn />, loader: loginLoader },
      { path: "sentemail", element: <SentEmail />, loader: loginLoader },
      {
        path: "reestablecer-contrasena",
        element: <Restablecer />,
        loader: loginLoader,
      },
      {
        path: "olvidarcontrasena",
        element: <Contrasena />,
        loader: loginLoader,
      },
      {
        path: "terminos-y-condiciones",
        element: <Terminos />,
      },
      {
        path: "politica-de-privacidad",
        element: <Politicas />,
      },
      {
        path: "inicioEncuesta",
        element: <InicioEncuesta/>,
      },
      {
        path: "encuesta",
        element: <Encuesta/>,
      },
      {
        path: "encuesta2",
        element: <Encuesta2/>,
      },
      {
        path: "encuesta3",
        element: <Encuesta3/>,
      },
      {
        path: "finEncuesta",
        element: <FinEncuesta/>,
      },
      { path: "logout", action: logoutAction },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
