import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import MapApp from "./Maps";
import { Button, FormSelect } from "react-bootstrap";
import { Link } from "react-router-dom";

function Thirty() {
  const [location, setLocation] = useState({});
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  // Estados para manejar las imágenes
  const [frontImage, setFrontImage] = useState(null);
  const [lightBillImage, setLightBillImage] = useState(null);

  const handleFrontImageChange = (event) => {
    setFrontImage(event.target.files[0]);
  };

  const handleLightBillImageChange = (event) => {
    setLightBillImage(event.target.files[0]);
  };

  // Aquí, asumo que quieres comprobar si la dirección y los selects tienen valores
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [direccion, setDireccion] = useState("");

  useEffect(() => {
    // Verifica si todos los campos requeridos están llenos
    const checkIfAllFieldsAreFilled = () => {
      return (
        selectedCondition &&
        selectedArea &&
        direccion &&
        location.city &&
        location.department &&
        location.district &&
        location.neighborhood
      );
    };

    setIsNextButtonEnabled(checkIfAllFieldsAreFilled());
  }, [selectedCondition, selectedArea, direccion, location]);

  return (
    <div>
      <Header textHeader="Datos de domicilio" />
      <div className="container">
        <p className="text-center">Por favor ingresa los datos solicitados</p>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="condicionesVivienda">
              Tenencia de la vivienda
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <FormSelect
                name="condicionesVivienda"
                id="condicionesVivienda"
                className="form-control rounded-pill"
                value={selectedCondition}
                onChange={(e) => setSelectedCondition(e.target.value)}
              >
                <option value="">Seleccionar</option>
                <option value="Opción 1">Propia</option>
                <option value="Opción 2">Alquilada</option>
                <option value="Opción 3">Anticretico</option>
                <option value="Opción 4">Cedida</option>
                <option value="Opción 5">Cuidador</option>
                <option value="Opción 6">Alojado</option>
                <option value="Opción 7">Casero</option>
                <option value="Opción 8">Familiar</option>
                <option value="Opción 9">Otro</option>
              </FormSelect>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="area">
                Área{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <FormSelect
                name="area"
                id="area"
                className="form-control rounded-pill"
              >
                <option value="">Seleccionar</option>
                <option value="Urbana">Urbana</option>
                <option value="Rural">Rural</option>
              </FormSelect>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group rounded-pill">
              {" "}
              <label htmlFor="area">
                Ubicación{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <MapApp setLocation={setLocation} />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group ">
              <label htmlFor="ciudad">
                Ciudad{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="ciudad"
                id="ciudad"
                className="form-control rounded-pill"
                value={location.city || ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 mt-3">
            Departamento
            <input
              type="text"
              name="department"
              id="department"
              className="form-control rounded-pill"
              value={location.department || ""}
              readOnly
            />
          </div>

          <div className="col-md-2 mt-3">
            Provincia
            <input
              type="text"
              name="province"
              id="province"
              className="form-control rounded-pill"
              value={location.district || ""}
              readOnly
            />
          </div>

          <div className="col-md-2 mt-3">
            Municipio
            <input
              type="text"
              name="province"
              id="province"
              className="form-control rounded-pill"
            />
          </div>

          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="zona">
                Zona
                <span className="text-danger">
                  <strong>*</strong>
                </span>{" "}
              </label>
              <input
                type="text"
                name="zona"
                id="zona"
                className="form-control rounded-pill"
                value={location.neighborhood || ""}
              />
            </div>
          </div>

          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="direccion">
                Direccion
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="direccion"
                id="direccion"
                className="form-control rounded-pill"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex flex-md-row-reverse text-center mt-4">
              <div className="col-md-8 p-md-2 p-2 px-4">
                <label
                  htmlFor="frontImage"
                  className="w-100 btn btn-light btn-lg rounded-3 shadow"
                >
                  <i className="bi bi-camera"></i> Tomar una fotografía del
                  frontis de su domicilio
                </label>
                <input
                  type="file"
                  id="frontImage"
                  onChange={handleFrontImageChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-md-row-reverse text-center mt-4">
              <div className="col-md-8 p-md-2 p-2 px-4">
                <label
                  htmlFor="lightBillImage"
                  className="w-100 btn btn-light btn-lg rounded-3 shadow"
                >
                  <i className="bi bi-camera"></i> Tomar una fotografía la
                  factura de luz
                </label>
                <input
                  type="file"
                  id="lightBillImage"
                  onChange={handleLightBillImageChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center mt-4">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link
                to={isNextButtonEnabled ? "/step31" : "#"}
                className={isNextButtonEnabled ? "" : "disabled-link"}
              >
                <Button
                  type="button"
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                  disabled={!isNextButtonEnabled}
                >
                  Siguiente
                </Button>
              </Link>
            </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="/step29">
                <button
                  type="submit"
                  className="w-100 btn btn-light btn-lg rounded-pill shadow"
                >
                  Atrás
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thirty;
