import React from 'react';
import Header from "../../Components/Header.jsx";
import './Encuesta.css';
import { Link } from 'react-router-dom';

export default function FinEncuesta() {

  return (
    <div className='container'>
        <Header textHeader="Gracias por tu colaboración!!!"/>
        <div className='anchos shadow rounded-4 mb-12 col-sm-12' style={{color:"#2C3F81"}}>
            <h5>Tomaremos en cuenta tus respuestas, para mejorar.</h5>
        </div>
        <div className='container col-xl-4'>
            <Link to={"https://www.kivocash.com"}>
                <button
                    className="btn btn-primary mt-2 w-100"
                    type="submit"
                    style={{color:"white", font:'LemonMilk', fontSize:"20px"}}
                >
                    Finalizar
                </button>
            </Link>
        </div>
        
    </div>
  )
}
