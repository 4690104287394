import React, { useRef, useEffect, useState } from "react";
import Header from "../../Components/Header.jsx";
import { useLoaderData, useNavigate } from "react-router-dom";
import axios from "axios";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import "./Step13.css";

function StepThirteen() {
  const Step = "/step15"; //this is the next step
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  const token = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cameraStream, setCameraStream] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const [capturedBlob, setCapturedBlob] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );
  const [error, setError] = useState(null);

  const clearError = () => setError(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const guidesRef = useRef(null);
  const handleCloseCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null); // Establece la cámara a null para indicar que está cerrada
    }
  };

  useEffect(() => {
    const getCamera = async () => {
      try {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const facingMode = isMobile ? "user" : "environment";

        const constraints = {
          video: {
            facingMode: facingMode,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setCameraStream(stream);

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            updateGuideSize();
            if (facingMode === "user") {
              videoRef.current.style.transform = "scaleX(-1)";
            }
          };
        }
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
      }
    };

    getCamera();

    const handleOrientationChange = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
      updateGuideSize();
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateGuideSize();
  }, [isPortrait]);

  const handleCapture = () => {
    if (videoRef.current && canvasRef.current && guidesRef.current) {
      const context = canvasRef.current.getContext("2d");
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;

      // Tamaños y posiciones del recuadro de guías
      const guideRect = guidesRef.current.getBoundingClientRect();
      const videoRect = videoRef.current.getBoundingClientRect();

      // Calcular las proporciones para recortar correctamente
      const scaleX = videoWidth / videoRect.width;
      const scaleY = videoHeight / videoRect.height;

      const x = (guideRect.left - videoRect.left) * scaleX;
      const y = (guideRect.top - videoRect.top) * scaleY;
      const width = guideRect.width * scaleX;
      const height = guideRect.height * scaleY;

      // Ajustar el tamaño del canvas al del recuadro
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      // Dibujar solo el área del recuadro en el canvas
      context.drawImage(
        videoRef.current,
        x,
        y,
        width,
        height,
        0,
        0,
        width,
        height
      );

      canvasRef.current.toBlob((blob) => {
        setImageCaptured(URL.createObjectURL(blob));
        setCapturedBlob(blob);
        setShowPreview(false);
      }, "image/jpeg");
    }
  };

  const updateGuideSize = () => {
    if (videoRef.current && guidesRef.current) {
      const containerWidth = videoRef.current.offsetWidth;
      const containerHeight = videoRef.current.offsetHeight;

      let width, height;
      // Definir el tamaño del óvalo
      width = containerWidth * 0.7; // El ancho será el 60% del ancho del contenedor
      height = width * 1.5; // El alto será 1.5 veces el ancho

      // Asegurar que el óvalo no sea más alto que el contenedor
      if (height > containerHeight) {
        height = containerHeight * 0.9; // El alto será el 80% del alto del contenedor
        width = height * (2 / 3); // El ancho ajustado para mantener la proporción
      }

      guidesRef.current.style.width = `${width}px`;
      guidesRef.current.style.height = `${height}px`;
      guidesRef.current.style.borderRadius = "50%"; // Hacer que las esquinas sean redondas para formar un óvalo
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    const imageFile = new File([capturedBlob], "image.jpg", {
      type: "image/jpeg",
    });
    formData.append("archivo", imageFile);
    formData.append("idSolicitud", localStorage.getItem("idSolicitud"));
    formData.append("tipo", "SELFIE");
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BACKEND}/creditos/archivos/selfie`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      console.log("image uploaded succesfully", response.data);
      setLoading(false);
      handleCloseCamera();
      manejarClick();
    } catch (error) {
      console.error("Error uploading image:", error.response.data.mensaje);
      setLoading(false);
      setError({
        message: error.response.data.mensaje,
        type: "danger",
      });
    }
  };

  return (
    <div>
      <Header textHeader={<React.Fragment>Tómate una selfie</React.Fragment>} />
      <center>
        <h4>Centra la cara en el círculo</h4>
      </center>
      <div className="container13">
        <ErrorHandling error={error} clearError={clearError} />
        <div className="camera-container">
          {showPreview ? (
            <>
              <video
                ref={videoRef}
                autoPlay
                style={{ width: "100%" }}
                onClick={handleCapture}
              />
              <div ref={guidesRef} className="camera-guides-face">
                <button
                  className="floating-camera-button-face"
                  onClick={handleCapture}
                >
                  <i className="camera-icon bi bi-camera px-1"></i>
                </button>
              </div>{" "}
            </>
          ) : (
            <center>
              <img
                src={imageCaptured}
                alt="Captured"
                style={{ width: "50%", objectFit: "cover" }}
              />
            </center>
          )}

          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>

        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center">
            <div className="col-md-3 p-md-2 p-2 px-4">
              <button
                type="submit"
                disabled={!imageCaptured}
                onClick={handleUpload}
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
              >
                {loading ? "Enviando..." : "Siguiente"}
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="d-flex flex-md-row-reverse row text-center">
            <div className="col-md-3 p-md-2 p-2 px-4">
              {imageCaptured ? (
                <a href="/step13">
                  <button className="my-4 w-100 btn btn-light btn-lg rounded-pill shadow">
                    Tomar otra foto
                  </button>
                </a>
              ) : (
                <button
                  onClick={handleCapture}
                  className="my-4 w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                >
                  Tomar Foto
                </button>
              )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
export default StepThirteen;
