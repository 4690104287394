import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import ErrorHandling from "../../Components/ErrorHandling.jsx";

const Items = ({ URL, numero }) => {
  const token = useLoaderData();
  const [documents, setDocuments] = useState([]);
  const [fileName, setFileName] = useState("");
  const [numberInput, setNumberInput] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [isNumberInputValid, setIsNumberInputValid] = useState(null);
  const [isReadyToSend, setIsReadyToSend] = useState(false); // Nuevo estado
  const [prevNumberInput, setPrevNumberInput] = useState(""); // Nuevo estado para almacenar el valor anterior de numberInput
  const [error, setError] = useState(null);

  const clearError = () => setError(null);

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    const uploadedDocuments = Array.from(files);
    setDocuments(uploadedDocuments);
    checkValidity(uploadedDocuments, numberInput);

    // Actualiza el nombre del archivo
    if (uploadedDocuments.length > 0) {
      setFileName(uploadedDocuments[0].name);
    } else {
      setFileName("");
    }
  };

  const handleNumberInputChange = (event) => {
    const value = event.target.value;
    setPrevNumberInput(value); // Almacena el valor anterior
    setNumberInput(value);
    checkValidity(documents, value);
  };

  const checkValidity = (uploadedDocuments, numberValue) => {
    const isDocumentsValid = uploadedDocuments.length > 0;
    const isNumberValid = numberValue !== "";

    setIsValid(isDocumentsValid);
    setIsNumberInputValid(isNumberValid);
  };

  useEffect(() => {
    // Efecto para isValid
    if (isValid !== null) {
      setIsReadyToSend(isValid && isNumberInputValid);
    }
  }, [isValid, isNumberInputValid]);

  useEffect(() => {
    // Efecto para isNumberInputValid
    if (isNumberInputValid !== null) {
      setIsReadyToSend(isValid && isNumberInputValid);
    }
  }, [isValid, isNumberInputValid]);

  useEffect(() => {
    // Efecto para enviar datos al backend
    if (isReadyToSend) {
      sendFormDataToBackend();
    }
  }, [isReadyToSend]);

  const sendFormDataToBackend = async () => {
    try {
      const formData = new FormData();
      formData.append("idSolicitud", localStorage.getItem("idSolicitud"));
      documents.forEach((file, fileIndex) => {
        formData.append(`archivo${fileIndex}`, file);
      });
      formData.append("monto", numberInput);
      formData.append("tipo", URL + numero);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND}/creditos/archivos/` + URL,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response from server:", response.data);
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response.data.mensaje
      );
      setError({
        message:
          error.response.data.finalizado === false &&
          error.response.data.mensaje ===
            "Los documentos no son legibles o no tienen la informacion necesaria."
            ? error.response.data.mensaje
            : "Debes elegir un Archivo",
        type: "danger",
      });

      // Restaurar el valor anterior de numberInput en caso de error
      setNumberInput(prevNumberInput);

      // Limpiar el estado del archivo subido anteriormente
      setDocuments([]);

      // Restablecer el estado después de manejar el error
      setIsValid(null);
      setIsNumberInputValid(null);
    }
  };

  return (
    <div>
      <ErrorHandling error={error} clearError={clearError} />
      <div className="row">
        <div className="col-md-6">
          <Form.Control
            type="number"
            value={numberInput}
            onChange={handleNumberInputChange}
          />
        </div>
        <div className="col-md-6">
          <label
            className={`w-100 ${
              isValid && isNumberInputValid
                ? "bg-success text-white"
                : "bg-dark text-black"
            } rounded mb-2 p-3 text-center border-primary border-3`}
          >
            <i className="bi bi-upload"></i>{" "}
            {isValid && isNumberInputValid
              ? `${fileName}`
              : "Subir Plan de Pagos"}
            <input
              type="file"
              id="fileInput"
              accept=".pdf,.jpeg,.jpg"
              multiple
              onChange={handleDocumentChange}
              style={{ display: "none" }}
              disabled={!isNumberInputValid}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Items;
