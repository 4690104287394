import React, { useEffect } from "react";

const ErrorHandling = ({ error, clearError }) => {
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        clearError();
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  if (!error) {
    return null;
  }

  const alertClass = `alert alert-${error.type || "danger"}`; // 'danger' como predeterminado

  return <div className={alertClass}>{error.message}</div>;
};

export default ErrorHandling;
