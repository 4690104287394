import React, { useState } from 'react';
import Header from "../../Components/Header.jsx";
import imagenFeliz from "../encuesta/emoji feliz.png";
import imagenMedio from "../encuesta/emoji mas o menos.png";
import imagenEnojado from "../encuesta/emoji enojado.png";
import './Encuesta.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Encuesta3() {
    const navigate = useNavigate();
    const [recomendar_servicio, setAtencion] = useState('');
    const [response, setResponse] = useState('');


    // Función para manejar cambios en la selección de recomendar servicio
    const handleAtencionChange = (e) => {
        const value = e.target.value;
        setAtencion(value);
        // Guardar la selección de recomendar servicio en localStorage
        localStorage.setItem('recomendar_servicio', value);
    };

    const handleGuardar = () => {
        localStorage.setItem('recomendar_servicio', recomendar_servicio);
        console.log('Datos guardados en localStorage:', recomendar_servicio);
        const datosLocalStorage = JSON.parse(localStorage.getItem('formData'));
        console.log(datosLocalStorage.nombre_encuesta);
        const nombre_encuesta = datosLocalStorage.nombre_encuesta;
        const apellido_encuesta = datosLocalStorage.apellido_encuesta;
        const atencion = localStorage.getItem('atencion');
        console.log(atencion);
        const tiempo_atencion = localStorage.getItem('tiempo_atencion');
        const data = {
            nombre_encuesta: nombre_encuesta,
            apellido_encuesta: apellido_encuesta,
            atencion: atencion,
            tiempo_atencion: tiempo_atencion,
            recomendar_servicio: recomendar_servicio,
        };
        console.log(data);
        axios.post( `${process.env.REACT_APP_API_BACKEND}/guardar-respuestas`, data)
        .then((response) => {
          console.log('Respuesta de la API:', response.data);
          setResponse(response.data);
        })
        .catch((error) => {
          console.error('Error al enviar los datos a la API:', error);
        });
        localStorage.clear();
       navigate('/finEncuesta');
    };
  return (
    <div className='container'>
        <Header textHeader="¿Qué tan probable es que recomiende nuestro servicio a otros?"/>
        <div className='anchos shadow rounded-4 mb-3 col-sm-12' style={{textAlign:"center", color:"white"}}>
            
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-3' src={imagenFeliz} alt="emoji" style={{textAlign:"center"}}/>
                <div>   
                <input className="form-check-input" type="radio" name="recomendar_servicio" id="inlineRadio1" value="muy_probable" checked={recomendar_servicio === 'muy_probable'} onChange={handleAtencionChange}/>
                <label className="form-check-label" htmlFor="inlineRadio1">Muy probable</label>
                </div>
                
            </div>
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-4' src={imagenMedio} alt="emoji" style={{textAlign:"center"}}/>
                <div>
                    <input className="form-check-input" type="radio" name="recomendar_servicio" id="inlineRadio2" value="poco_probable" checked={recomendar_servicio === 'poco_probable'} onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="inlineRadio1">Poco probable</label>
                </div>                
            </div>
            <div className="form-check form-check-inline col-md-3 col-sm-4">
                <img className='image col-md-12 col-sm-4' src={imagenEnojado} alt="emoji" style={{textAlign:"center"}}/>
                <div>
                    <input className="form-check-input" type="radio" name="recomendar_servicio" id="inlineRadio3" value="no_recomendable" checked={recomendar_servicio === 'no_recomendable'} onChange={handleAtencionChange}/>
                    <label className="form-check-label" htmlFor="inlineRadio3">No lo recomendaria</label>
                </div>                
            </div>
        </div>
        <div className='container col-xl-4'>
                <button onClick={handleGuardar}
                    className="btn btn-primary mt-2 w-100"
                    type="submit"
                    style={{color:"white", font:'LemonMilk', fontSize:"20px"}}
                >
                    Siguiente
                </button>
        </div>
        
    </div>
  )
}
