import React from "react";
import "./Step3.css";
import { useNavigate, useLoaderData } from "react-router-dom";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

function StepThree() {
  const navigate = useNavigate();
  const Step = "/step4";
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  const token = useLoaderData();
  console.log("step en paso 3:", token);
  return (
    <div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center centered">
          <div
            className={`row ${
              window.innerWidth <= 576 ? "bg-white rounded-5 shadow m-2" : ""
            }`}
          >
            <div className="col-md-4">
              <div className="d-flex align-items-center justify-content-center h-100">
                <img
                  src={require("./folks-shopping-bags_1.png")}
                  className="w-75 w-sm-25 py-3"
                  alt="shopping-bags"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="d-flex align-items-center justify-content-center h-100 py-3">
                <h2
                  onClick={manejarClick}
                  className="bg-primary py-4 shadow px-4 rounded-4 text-center text-white w-100"
                >
                  Ver Productos
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThree;
