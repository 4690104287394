import React, { useState, useEffect } from "react";
import axios from "axios";
import "./profile.css";
import Header from "../../Components/Header.jsx";
import { Link, useNavigate } from "react-router-dom";
import DisclaimerModal from "../../Components/PoliticasDePrivacidad.jsx";
import Calendario from "../../Components/Calendario.jsx";
import { UpdateLocalProfile } from "./UpdateLocalProfile.jsx";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import { format } from "date-fns";

const Profile = () => {
  const navigate = useNavigate();
  const Step = "/step5";

  const manejarClick = () => {
    handleUpdate(Step);
    navigate(Step);
  };
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTerminos, setShowTerminos] = useState(false);
  const handleClose = () => setShowDisclaimer(false);
  const handleShow = () => setShowDisclaimer(true);
  const handleCloseT = () => setShowTerminos(false);
  const handleShowT = () => setShowTerminos(true);
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const [alert, setAlert] = useState({ visible: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const ID = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [formData, setFormData] = useState({
    nombres: localStorage.getItem("nombres") || "",
    primerApellido: localStorage.getItem("primerApellido") || "",
    segundoApellido: localStorage.getItem("segundoApellido") || "",
    numeroDocumento: localStorage.getItem("numeroDocumento") || "",
    fechaNacimiento: localStorage.getItem("fechaNacimiento") || "",
  });
  useEffect(() => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      setFormData((prevFormData) => ({
        ...prevFormData,
        fechaNacimiento: formattedDate,
      }));
    }
  }, [selectedDate]);
  useEffect(() => {
    if (formData.fechaNacimiento) {
      const fechaNacimiento = new Date(formData.fechaNacimiento);
      fechaNacimiento.setDate(fechaNacimiento.getDate() + 1);
      setSelectedDate(fechaNacimiento);
    }
  }, [formData.fechaNacimiento]);

  const handleDataVerification = async (event) => {
    setLoading(true);

    const url = `${process.env.REACT_APP_API_BACKEND}/system/usuarios/validacion-datos/${ID}`;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        console.log(JSON.stringify(data.datos.id));
        localStorage.setItem("idSolicitud", data.datos.id);
        manejarClick();
      } else {
        const data = await response.json();
        console.log(await data.mensaje);
        setError({
          message: data.mensaje,
          type: "danger",
        });
        setLoading(false);
        navigate("/profile");
      }
    } catch (error) {
      console.log("Verification failed! ", error);
      setError({
        message: "Ocurrió un error inesperado, intente de nuevo",
        type: "danger",
      });
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      alert("Por favor, selecciona una fecha.");
      return;
    }

    console.log("format date", selectedDate); // Envía la fecha formateada

    const nameRegex =
      /^[A-Za-záéíóúÁÉÍÓÚñÑüÜ'-]+(?:\s[A-Za-záéíóúÁÉÍÓÚñÑüÜ'-]+)*$/;

    // Check if names and surnames are filled in and valid
    if (!nameRegex.test(formData.nombres.trim())) {
      setAlert({
        visible: true,
        message: "El nombre contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }
    if (!nameRegex.test(formData.primerApellido.trim())) {
      setAlert({
        visible: true,
        message: "El primer apellido contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }
    if (
      formData.segundoApellido &&
      !nameRegex.test(formData.segundoApellido.trim())
    ) {
      setAlert({
        visible: true,
        message: "El segundo apellido contiene caracteres no válidos.",
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 3000);
      return;
    }
    const carnetIdentidad = /^\d{6,}$/;
    if (!carnetIdentidad.test(formData.numeroDocumento)) {
      setAlert({ visible: true, message: "Verifique su numero de Carnet." });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 2000);
      return;
    }

    // Proceed with form submission if all checks pass
    setLoading(true);
    console.log("datos a enviarse", formData);
    //only because you have to send it twice please delete after

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BACKEND}/system/usuarios/${ID}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      console.log(response);
      UpdateLocalProfile(
        formData.nombres,
        formData.primerApellido,
        formData.segundoApellido,
        formData.numeroDocumento,
        formData.fechaNacimiento
      );
      handleDataVerification();
    } catch (error) {
      setLoading(false);
      console.error("Error de respuesta:", error);
      setAlert({
        visible: true,
        message: JSON.stringify(error),
      });
      setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 3000);
    }
  };

  return (
    <div className="mx-4">
      <Header textHeader="CORRIGE TUS DATOS" />
      <h6>Registra tus datos como están en tu carnet de identidad</h6>

      {loading && <div className="alert alert-success">Enviando datos...</div>}
      {alert.visible && (
        <div className="alert alert-danger">{alert.message}</div>
      )}
      <ErrorHandling error={error} clearError={clearError} />
      <div className="anchos bg-white shadow rounded-4">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Nombre (s)<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="nombres"
                  className="form-control"
                  value={formData.nombres}
                  onChange={handleChange}
                  placeholder="Nombre (s)"
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Primer Apellido<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="primerApellido"
                  className="form-control"
                  value={formData.primerApellido}
                  onChange={handleChange}
                  placeholder="Primer Apellido"
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label>Segundo Apellido</label>
                <input
                  type="text"
                  name="segundoApellido"
                  className="form-control"
                  value={formData.segundoApellido}
                  onChange={handleChange}
                  placeholder="Segundo Apellido"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 py-md-4">
                <label>
                  Carnet de Identidad<span className="text-danger"> *</span>
                </label>
                <input
                  type="number"
                  name="numeroDocumento"
                  className="form-control"
                  value={formData.numeroDocumento}
                  onChange={handleChange}
                  placeholder="Carnet de Identidad"
                  required
                />
              </div>
              <div className="col-md-6 py-md-4">
                <label>
                  Fecha de Nacimiento<span className="text-danger"> *</span>
                </label>

                <Calendario
                  minYearSet="18"
                  value={selectedDate}
                  onChange={setSelectedDate}
                />
              </div>
            </div>
            <div className="row pt-2">
              <div className="col">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span className="px-2">
                  Acepto las &nbsp;
                  <Link className="text-secondary" onClick={handleShow}>
                    Políticas de Privacidad
                  </Link>
                  &nbsp;y los&nbsp;
                  <Link className="text-secondary" onClick={handleShowT}>
                    Términos y Condiciones
                  </Link>
                </span>

                <DisclaimerModal
                  show={showDisclaimer}
                  handleClose={handleClose}
                  title="Políticas de Privacidad"
                  url="https://onboarding-dev.kivocash.com/politica-de-privacidad"
                />
                <DisclaimerModal
                  show={showTerminos}
                  handleClose={handleCloseT}
                  title="Términos y Condiciones"
                  url="https://onboarding-dev.kivocash.com/terminos-y-condiciones"
                />
              </div>
            </div>
            <button
              disabled={!isChecked}
              type="submit"
              className="btn btn-primary mt-3"
            >
              Actualizar mis datos
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
