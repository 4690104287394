import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header.jsx";
import React from "react";
import "./step5.css";
import { handleUpdate } from "../../Components/ActualizarPasoActual.jsx";

const StepFive = () => {
  const navigate = useNavigate();
  const Step = "/step6";
  const manejarClick = () => {
    handleUpdate(Step); // Pasa Step como argumento
    navigate(Step);
  };
  return (
    <div>
      <div>
        <div>
          <center>
            <Header textHeader="¡Importante!" />
          </center>
        </div>

        <div className="container pt-4">
          <div className="row ">
            <div className="col-md-6 d-flex justify-content-center">
              <div className="anchocuadro">
                <div className="text-center bg-white rounded p-4 shadow">
                  <p>
                    Para continuar con tu solicitud de préstamo debes tener a la
                    mano lo siguiente:
                  </p>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i className="bi bi-person-badge display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>Cédula de Identidad vigente</p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i className="bi bi-receipt display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>
                        Aportes a la Gestora o Extracto Bancario (3 últimos meses)
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i className="bi bi-bank display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>
                        Contrato de Trabajo o Boletas de Pago
                      </p>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i className="bi bi-credit-card display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>
                        Cuenta Bancaria a tu Nombre
                      </p>
                    </div>
                  </div><div className="row d-flex align-items-center">
                    <div className="col-2 py-md-4">
                      <i className="bi bi-whatsapp display-4"></i>
                    </div>
                    <div className="col-9">
                      <p>
                        Número de Whatsapp
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <div className="p-4 anchocuadro">
                <img
                  src={require("./folks-seated-man-with-tablet.png")}
                  className="w-100"
                  alt="seated-man-with-tablet"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex flex-md-row-reverse row text-center mt-4">
              <div className="col-md-3 p-md-2 p-2 px-4">
                <button
                  type="button"
                  onClick={manejarClick}
                  className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                >
                  Continuar
                </button><br /><br /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFive;
