import React, { useState } from "react";
import Header from "../../Components/Header";
import ErrorHandling from "../../Components/ErrorHandling.jsx";
import { Link, useNavigate } from "react-router-dom";

function OlvidarContrasena() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const clearError = () => setError(null);
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_BACKEND}/public/recuperar-contrasena`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          correoElectronico: email,
        }),
      });

      if (response.ok) {
        const data = await response;
        console.log(JSON.stringify(data.mensaje));
        setLoading(false);
        navigate("/sentemail");
      } else {
        console.log(response);
        setError({
          message: error.response.data.mensaje,
          type: "danger",
        });
        setLoading(false);
      }
    } catch (error) {
      setMessage("Verification failed! ", error.response.data.mensaje);
      setError({
        message: error.response.data.mensaje,
        type: "danger",
      });
    }
  };

  return (
    <div>
      <Header textHeader="Recuperar tu contraseña" />
      <ErrorHandling error={error} clearError={clearError} />
      <form onSubmit={handleSubmit}>
        <div className="ancho-img">
          <div className="shadow">
            <img
              src={require("./Logo-KIVO-PNG.png")}
              className="w-100 rounded-top-4"
              alt="logo"
            />
            <div className="bg-white px-2 px-md-4 pb-2">
              <div className="pt-3">
                <h4 className="text-secondary">¿Olvidaste tu contraseña?</h4>
                <label>Ingresa tu Correo Electrónico:</label>
                <input
                  type="email"
                  value={email}
                  placeholder="Escribe tu correo"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="bg-white px-2 px-md-4 pb-2">
              <button
                className="btn btn-primary mt-3 w-100"
                type="submit"
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar"}
              </button>
            </div>

            <p className="py-3 text-center">
              ¿Aún no tienes una cuenta? <br />
              <Link to="/">Regístrate aquí</Link>
            </p>
          </div>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default OlvidarContrasena;
