import React from "react";
import { Popover, OverlayTrigger, ButtonToolbar } from "react-bootstrap";

function IconWithPopover1() {
  const popoverTop = (
    <Popover id="popover-positioned-top" title="Popover top">
      <p class="m-4">Ingrese el Nombre completo de su referencia personal</p>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger trigger="hover" placement="top" overlay={popoverTop}>
        <i class="bi bi-question-circle-fill text-success"></i>
      </OverlayTrigger>
    </ButtonToolbar>
  );
}

export default IconWithPopover1;
