export const enviarNetbank = async() => {
    const ID = localStorage.getItem("idSolicitud");
    const token = localStorage.getItem("token");
  
    const url = `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/${ID}/enviar-datos-netbank`;
  
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
      throw new Error("Error en la respuesta del servidor: " + errorResponse.mensaje);
      }
  
      const data = await response.json();
      console.log("Respuesta del servidor:", data);
    } catch (error) {
      console.error("Hubo un error en la petición:", error.message);
      
    }
  }