import React, { useEffect, useState } from "react";
import "./login.css";
import Header from "../../Components/Header.jsx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [url, setUrl] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const codigo = queryParams.get("codigo");
  const url2 = `${process.env.REACT_APP_API_BACKEND}/public/verificar-correo`;
  const url1 = `${process.env.REACT_APP_API_BACKEND}/public/login`;

  let body = JSON.stringify({ usuario: email, contrasena: password });
  useEffect(() => {
    if (codigo) {
      body = JSON.stringify({ codigo: codigo });
      setUrl(`${process.env.REACT_APP_API_BACKEND}/public/verificar-correo`);
      handleSubmit(null); // Llamar a handleSubmit con un argumento nulo
    } else {
      setUrl(`${process.env.REACT_APP_API_BACKEND}/public/login`);
      body = JSON.stringify({ usuario: email, contrasena: password });
    }
  }, [codigo, email, password]);

  const showAlert = () => {
    setAlertMessage(true);
    setTimeout(() => {
      setAlertMessage(false);
    }, 2000);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    if (event) {
      event.preventDefault();
    }
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(!codigo ? url1 : url2, {
        method: "POST",
        headers,
        body,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("datos", data.datos);
        setLoading(false);
        console.log("response login: ", data);
        const token = data.datos?.token;
        localStorage.setItem("token", token);
        localStorage.setItem("nombres", data.datos.nombres);
        localStorage.setItem("primerApellido", data.datos.primerApellido);
        localStorage.setItem("segundoApellido", data.datos.segundoApellido);
        localStorage.setItem("id", data.datos.id);
        localStorage.setItem("numeroDocumento", data.datos.numeroDocumento);
        localStorage.setItem("fechaNacimiento", data.datos.fechaNacimiento);
        const decodedToken = jwtDecode(token);
        localStorage.setItem("expiration", decodedToken.exp);
        navigate("/step2");
      } else if (response.status === 401) {
        // Manejar el caso de respuesta 401 específicamente
        console.error("Error: Credenciales incorrectas");
        setLoading(false);
        showAlert();
        navigate("/signin"); // Mostrar un mensaje al usuario
      } else {
        // Manejar otros códigos de estado de error
        console.error("Error 1:", response.statusText);
        setLoading(false);
        showAlert(true);
        navigate("/signin");
      }
    } catch (error) {
      // Manejar errores de red u otros errores
      showAlert(true);
      setLoading(false);
      console.error("Error 2:", error);
    }
  };

  return (
    <div>
      <Header textHeader="¡Ingresa aquí!" />
      <div className="ancho-img">
        {alertMessage && (
          <div className="alert alert-danger">Revise los datos ingresados</div>
        )}
        <div className="shadow">
          <img
            src={require("./Logo-KIVO-PNG.png")}
            className="w-100 rounded-top-4"
            alt="logo kivo"
          />
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-2 px-md-4 pb-2">
              <div className="pt-3">
                <label>Correo</label>
                <input
                  className="form-control rounded-4"
                  type="email"
                  value={email}
                  placeholder="Escribe tu correo"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <div className="pt-3">
                  <label>Contraseña</label>
                  <div className="input-group">
                    <input
                      className="form-control rounded-start-4"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Escriba su contraseña"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span
                      className="input-group-text rounded-end-4"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <Link to="/olvidarcontrasena" className="text-secondary">
                <p className="pt-2">¿Olvidaste tu contraseña?</p>
              </Link>
              <button
                className="btn btn-primary mt-2 w-100"
                type="submit"
                disabled={loading}
              >
                {loading ? "Ingresando..." : "Ingresar"}
              </button>
              <p className="pt-3 text-center">
                ¿Aún no tienes una cuenta? <br />
                <Link to="/">Regístrate aquí</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
