import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import MapApp from "./Maps";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Thirtyone() {
  const [location, setLocation] = useState({});
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  
   // Estados para manejar las imágenes
   const [frontImage, setFrontImage] = useState(null);
   const [lightBillImage, setLightBillImage] = useState(null);

   const handleFrontImageChange = (event) => {
    setFrontImage(event.target.files[0]);
  };

  const handleLightBillImageChange = (event) => {
    setLightBillImage(event.target.files[0]);
  };


  // Aquí, asumo que quieres comprobar si la dirección y los selects tienen valores
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [direccion, setDireccion] = useState("");

  useEffect(() => {
    // Verifica si todos los campos requeridos están llenos
    const checkIfAllFieldsAreFilled = () => {
      return (
        selectedCondition &&
        selectedArea &&
        direccion &&
        location.city &&
        location.department &&
        location.district &&
        location.neighborhood
      );
    };

    setIsNextButtonEnabled(checkIfAllFieldsAreFilled());
  }, [selectedCondition, selectedArea, direccion, location]);

  return (
    <div>
      <Header textHeader="Datos laborales" />
      <div className="container">
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="empresacondicion">
                Empresa/institución
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="empresacondicion"
                id="empresacondicion"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="cargoactual">
                Cargo Actual{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="cargoactual"
                id="cargoactual"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="ubicacion">
                Ubicación
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <MapApp setLocation={setLocation} />
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="actividadempresarial">
                Actividad Empresarial{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <select
                name="actividadempresarial"
                id="actividadempresarial"
                className="form-control rounded-pill"
              >
                <option value="">Seleccionar</option>
                <option value="Opción 1">ABOGADO(A)</option>
                <option value="Opción 2">ADMINISTRADOR(A) DE EMPRESAS</option>
                <option value="Opción 3">AGRÓNOMO(A)</option>
                <option value="Opción 4">AMA DE CASA</option>
                <option value="Opción 5">ARQUITECTO(A)</option>
                <option value="Opción 6">AUDITOR(A)</option>
                <option value="Opción 7">CHOFER</option>
                <option value="Opción 8">CONTADOR(A)</option>
                <option value="Opción 9">ECONOMISTA</option>
                <option value="Opción 10">FISIOTERAPEUTA</option>
                <option value="Opción 11">FONIATRA</option>
                <option value="Opción 12">INGENIERO(A)</option>
                <option value="Opción 13">MAESTRO(A)</option>
                <option value="Opción 14">MEDICO</option>
                <option value="Opción 15">ODONTOLOGO(A)</option>
                <option value="Opción 16">PERIODISTA</option>
                <option value="Opción 17">SACERDOTE</option>
                <option value="Opción 18">SECRETARIA</option>
                <option value="Opción 19">VETERINARIO(A)</option>
                <option value="Opción 20">NO ESPECIFICADO</option>
                <option value="Opción 21">AGRICULTOR</option>
                <option value="Opción 22">SECRETARIA</option>
                <option value="Opción 23">CAJERA</option>
                <option value="Opción 24">INSPECTOR DE CRÉDITOS</option>
                <option value="Opción 25">ENCARGADO DE COMPUTO</option>
                <option value="Opción 26">LOCUTOR</option>
                <option value="Opción 27">ESTUDIANTE</option>
                <option value="Opción 28">ENFERMERA (O)</option>
                <option value="Opción 29">MODISTA</option>
                <option value="Opción 30">MECÁNICO</option>
                <option value="Opción 31">COMERCIANTE</option>
                <option value="Opción 32">MECANICO DENTAL</option>
                <option value="Opción 33">SOLDADOR</option>
                <option value="Opción 34">EMPLEADO</option>
                <option value="Opción 35">CARPINTERO</option>
                <option value="Opción 36">ALBAÑIL</option>
                <option value="Opción 37">ELECTRICISTA</option>
                <option value="Opción 38">CHAPERO O PINTOR</option>
                <option value="Opción 39">LIC. AUDITORIA</option>
                <option value="Opción 40">TRANSPORTISTA</option>
                <option value="Opción 41">MILITAR</option>
                <option value="Opción 42">POLICÍA</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="departamento">
                Departamento
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="departamento"
                id="departamento"
                className="form-control rounded-pill"
                value={location.department || ""}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="provincia">
                Provincia{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="provincia"
                id="provincia"
                className="form-control rounded-pill"
                value={location.district || ""}readOnly
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="municipio">
                Municipio
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="municipio"
                id="municipio"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="ciudad">
                Ciudad{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="ciudad"
                id="ciudad"
                className="form-control rounded-pill"
                value={location.city || ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="zona">
                Zona
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="zona"
                id="zona"
                className="form-control rounded-pill"
                value={location.neighborhood || ""}
                
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="direccion">
                Dirección{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="direccion"
                id="direccion"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="form-group">
              <label htmlFor="referencialaboral">
                Referencia Laboral
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="text"
                name="referencialaboral"
                id="referencialaboral"
                className="form-control rounded-pill"
              />
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="form-group">
              <label htmlFor="celular">
                Celular{" "}
                <span className="text-danger">
                  <strong>*</strong>
                </span>
              </label>
              <input
                type="number"
                name="celular"
                id="celular"
                className="form-control rounded-pill"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-4 p-md-2 p-2 px-4">
            <button
              type="button"
              
              className="w-100 btn btn-light btn-lg rounded-pill shadow"
            >
              <i class="bi bi-camera"></i> Tomar una fotografía del frontis de
              su lugar de trabajo
            </button>
          </div>
        </div>

        <div className="container">
        <div className="d-flex flex-md-row-reverse row text-center mt-4">
          <div className="col-md-3 p-md-2 p-2 px-4">
            <Link to={isNextButtonEnabled ? "/step32" : "#"} className={isNextButtonEnabled ? "" : "disabled-link"}>
              <Button
                type="button"
                className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                disabled={!isNextButtonEnabled}
              >
                Siguiente
              </Button>
            </Link>
          </div>
            <div className="col-md-3 p-md-2 p-2 px-4">
              <Link to="/step30">
                <button
                  type="submit"
                  className="w-100 btn btn-light btn-lg rounded-pill shadow"
                >
                  Atrás
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thirtyone;
