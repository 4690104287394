import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Redireccionador() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const obtenerDatos = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No hay token");
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BACKEND}/creditos/solicitudes/usuario`,
          {
            method: "GET",
            headers: headers,
          }
        );

        if (!response.ok) {
          console.log("No se pudo obtener el paso actual");
          return;
        }

        const data = await response.json();

        localStorage.setItem("idSolicitud", data.datos.id);
        // Seccion para habilitar o no el actualstep
        const actualStep = data.datos.pasoActual;
        //const actualStep = null; //
        localStorage.setItem("actualStep", actualStep);

        if (actualStep) {
          // Si obtuviste el paso actual, puedes hacer la redirección aquí
          const urlActual = location.pathname;
          if (urlActual === "/profile1") {
            return;
          } else if (actualStep !== urlActual) {
            navigate(actualStep);
          }
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    obtenerDatos();
  }, [navigate, location.pathname]); // Agregamos location.pathname como dependencia

  return null; // Este componente no renderiza nada en la interfaz de usuario
}

export default Redireccionador;
