import { redirect, useSubmit } from "react-router-dom";
import {action} from './Logout'


export function getTokenDuration(){
  const storedExpirationDate = localStorage.getItem('expiration');

  if (!storedExpirationDate) {
    return;
  }

  const expirationDate = new Date(storedExpirationDate * 1000);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime(); 
  
  if (duration < 0) {
    action();
    window.location.href = "/signin";
  }
  
  return; 
}

export function getAuthToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function tokenLoader() {
  const tokens = getAuthToken();
  return tokens;
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/signin");
  }
  return getAuthToken();
}
export function loginLoader() {
  const token = getAuthToken();

  if (token) {
    return redirect("/step2");
  }
  return getAuthToken();
}
export function profileLoader() {
  const token = getAuthToken();

  if (token) {
    return token;
  }
  return getAuthToken();
}
