import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header.jsx";
import { Button } from "react-bootstrap";

function StepTwentyeight() {
   // Estado para rastrear si un archivo PDF válido ha sido cargado
   const [isPdfUploaded, setIsPdfUploaded] = useState(false);

   const handleFileChange = (e) => {
     const selectedFile = e.target.files[0];
     if (selectedFile && selectedFile.type === "application/pdf") {
       // Realizar acciones con el archivo PDF, como almacenarlo o procesarlo
       console.log("Archivo PDF seleccionado:", selectedFile.name);
       setIsPdfUploaded(true); // Establecer el estado a verdadero cuando un PDF es cargado
     } else {
       alert("Por favor, seleccione un archivo PDF válido.");
       setIsPdfUploaded(false); // Establecer el estado a falso si el archivo no es un PDF
     }
   };
 
   return (
     <div>
       <Header textHeader="Verificación del buró" />
       <div className="container">
         <h2 className="text-center">Autorización de información</h2>
         <p>
           El siguiente texto es importante para que podamos revisar tu historial
           crediticio, por lo que debes descargarlo y subirlo firmado a pulso en
           la opción “Adjuntar documento firmado”
         </p>
         <div className="bg-white rounded p-5">
           <p>A quien corresponda:</p>
           <p>
             {" "}
             Yo GARANTE con C. I. Nro. IDNUMBER autorizo en forma expresa a
             GENCORP S.R.L. a solicitar información sobre mis antecedentes
             crediticios y otras cuentas por pagar de carácter económico,
             financiero y comercial registrados en el BI y en la CIC de la
             Autoridad del Sistema Financiero (ASFI) mientras dure mi relación
             contractual con el citado usuario.
           </p>
           <p>
             {" "}
             Asimismo autorizo a incorporar los datos crediticios y de cuentas
             por pagar a carácter económico, financiero y comercial derivados de
             la relación con GENCORP S.R.L. en la(s) base(s) de datos de
             propiedad de los Burós de Información que cuenten con licencia de
             funcionamiento de ASFI y la CIC.
           </p>
           <div className="row">
             <div className="col-9">
               <Button className="primary">
                 <label htmlFor="fileInput" className="primary">
                   <i className="bi bi-paperclip"></i> Adjuntar el documento
                   Firmado
                   <span className="text-danger">
                     <strong>*</strong>
                   </span>{" "}
                 </label>
                 <input
                   type="file"
                   id="fileInput"
                   accept=".pdf"
                   style={{ display: "none" }}
                   onChange={handleFileChange}
                 />
               </Button>
             </div>
             <div className="col-3">
               <Button className="primary">Descargar</Button>
             </div>
           </div>
         </div>
         <div className="alto">
           <div className="d-flex flex-md-row-reverse row text-center mt-4">
             <div className="col-md-3 p-md-2 p-2 px-4">
               <Link to="/step29">
                 <button
                   type="button"
                   className="w-100 btn btn-primary btn-lg rounded-pill shadow text-white"
                   disabled={!isPdfUploaded} // Deshabilita el botón si no se ha cargado un PDF
                 >
                   Siguiente
                 </button>
               </Link>
             </div>
             <div className="col-md-3 p-md-2 p-2 px-4">
               <Link to="/step27">
                 <button
                   type="submit"
                   className="w-100 btn btn-light btn-lg rounded-pill shadow"
                 >
                   Atrás
                 </button>
               </Link>
             </div>
           </div>
         </div>
       </div>
     </div>
   );
 }

export default StepTwentyeight;
